import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css'; // Global CSS
import theme from './theme/theme'; // Import your theme
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JavaScript
import 'react-datepicker/dist/react-datepicker.css';
import './assets/styles/custom.scss'; // Import custom CSS from assets
import './i18n'; // Import i18n configuration

ReactDOM.render(
    <Provider store={store}>
            <Router>
                <App />
            </Router>
    </Provider>,
    document.getElementById('root')
);
