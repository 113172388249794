import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Result } from 'antd';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate } from 'react-router-dom';
import logo from '../assets/images/blue-logo.png'; // Import the image file
import checked from '../assets/images/checked.png';

const ComingSoon = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    if (!isAuthenticated) {
        return <Navigate to="/signin" />;
    }

    return (
        <div className="login-bg vh-100">
            <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
                <div className="login-register">
                    
                    <div className="card text-center bg-white rounded-20 mt-5 mb-5">
                        <div className="card-body p-5">
                        <img src={logo} alt='Logo' width='200' className='' />
                        {/* <div><img src={checked} alt='Icon' width='80' className='mb-3' /></div> */}
                        <Result
                            status="success"
                            title="You are Successfully Registered"
                            subTitle="Your registration is currently being reviewed."
                            // extra={[
                            // <Button type="primary" key="console">
                            //     Go Console
                            // </Button>,
                            // <Button key="buy">Buy Again</Button>,
                            // ]}
                        />
                            
                            {/* 
                            <p className='text-muted'>Your registration is currently being reviewed.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
