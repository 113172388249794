import './SalesMen.css';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate } from 'react-router-dom';
import { ConfigProviderProps, RadioChangeEvent } from 'antd';
import { Radio, Tabs, TabsProps } from 'antd';
import Footer from '../components/Footer';
import Services from '../components/Services';
import Brands from '../components/Brands';
import Testimonials from '../components/Testimonials';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';
import Header3 from '../components/Header3';
import ProductGrid from '../components/ProductGrid';
import productImg from '../assets/images/p-img.png'; 




const SalesMen = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const btnText = "Download PDF"; 

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    const onChange = (key: string) => {
        console.log(key);
    };
      
    const productTabs = [
        {
            key: '1',
            label: 'New',
            products: [
                {
                    id: 1,
                    title: '2024 Toyota Land Cruiser',
                    image: productImg,
                    kilometer: '0 KM',
                    year: '2024',
                    color: 'White'
                },
                {
                    id: 2,
                    title: '2023 Nissan Patrol',
                    image: productImg,
                    kilometer: '10,000 KM',
                    year: '2023',
                    color: 'Black'
                },
                {
                    id: 3,
                    title: '2024 Toyota Land Cruiser',
                    image: productImg,
                    kilometer: '0 KM',
                    year: '2024',
                    color: 'White'
                },
            ]
        },
        {
            key: '2',
            label: 'Modified',
            products: [
                {
                    id: 3,
                    title: '2021 Modified Jeep Wrangler',
                    image: productImg,
                    kilometer: '5,000 KM',
                    year: '2021',
                    color: 'Green'
                },
                {
                    id: 4,
                    title: '2020 Modified Ford Mustang',
                    image: productImg,
                    kilometer: '12,000 KM',
                    year: '2020',
                    color: 'Red'
                }
            ]
        },
        {
            key: '3',
            label: 'Incoming',
            products: [
                {
                    id: 5,
                    title: '2025 Tesla Model X',
                    image: productImg,
                    kilometer: 'Brand New',
                    year: '2025',
                    color: 'Blue'
                },
                {
                    id: 6,
                    title: '2025 Mercedes-Benz S-Class',
                    image: productImg,
                    kilometer: 'Brand New',
                    year: '2025',
                    color: 'Silver'
                }
            ]
        }
    ];


    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <Header3 />
            <div className='container'>
                <Tabs defaultActiveKey="1" className="customTabs">
                    {productTabs.map(tab => (
                        <Tabs.Tab tab={tab.label} key={tab.key}>
                            <ProductGrid products={tab.products} btnText={btnText} />
                        </Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            <Footer />
        </div>
    );
};

export default SalesMen;
