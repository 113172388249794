import {
    SIGN_IN_SUCCESS,
    SIGN_IN_FAILURE,
    SIGN_OUT,
    SIGN_UP_SUCCESS,
    ADVANCE_SIGN_UP_SUCCESS,
    COMPANY_SIGN_UP_SUCCESS,
    COMPANY_ADVANCE_SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SET_LANGUAGE,
    TENDOR_SUBMIT,
    SUPPLY_CONTRACT_SUBMIT,
    REQUEST_QUOTATION_SUBMIT,
    MAKE_A_DEAL_SUBMIT,
} from './authTypes';

const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                error: null,
            };
        case COMPANY_SIGN_UP_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                error: null,
            };
        case SIGN_IN_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: null,
            };
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                error: null,
            };
        case SIGN_UP_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
                error: null,
            };
        case TENDOR_SUBMIT:
            return {
                ...state,
                tendor: action.payload,
                error: null,
            };
        case SUPPLY_CONTRACT_SUBMIT:
            return {
                ...state,
                supplyContract: action.payload,
                error: null,
            };
        case REQUEST_QUOTATION_SUBMIT:
            return {
                ...state,
                requestQuotation: action.payload,
                error: null,
            };
        case MAKE_A_DEAL_SUBMIT:
            return {
                ...state,
                makeaDeal: action.payload,
                error: null,
            };
        default:
            return state;
    }
};
