import '../components/Brands.css';
import React from 'react';
import { Link } from 'react-router-dom';
import b1 from '../assets/images/b1.png';
import b2 from '../assets/images/b2.png';
import b3 from '../assets/images/b3.png';
import b4 from '../assets/images/b4.png';
import b5 from '../assets/images/b5.png';
import b6 from '../assets/images/b6.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const Brands = () => {
    return (
        <div className='carouselCol pt-5 pb-5'>
            <div className='d-flex justify-content-between align-items-center'>
                <h2 className='mb-4 text-capitalize'>Our Brands</h2>
                <Link to='/brands' className='text-dark text-decoration-none'>Show All Brands <i class="bi bi-arrow-right ms-2"></i></Link>
            </div>
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false}>
                <div className='brand-carousel'>
                    <div className='row g-3'>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b1} alt='Brand' />
                                    <p>Audi</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b2} alt='Brand' />
                                    <p>BMW</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b3} alt='Brand' />
                                    <p>Ford</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b4} alt='Brand' />
                                    <p>Mercedes Benz</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b5} alt='Brand' />
                                    <p>Peugeot</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b6} alt='Brand' />
                                    <p>Volkswagen</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='brand-carousel'>
                    <div className='row g-3'>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b1} alt='Brand' />
                                    <p>Audi</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b2} alt='Brand' />
                                    <p>BMW</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b3} alt='Brand' />
                                    <p>Ford</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b4} alt='Brand' />
                                    <p>Mercedes Benz</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b5} alt='Brand' />
                                    <p>Peugeot</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-2'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 bg-white'>
                                    <img src={b6} alt='Brand' />
                                    <p>Volkswagen</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export default Brands;
