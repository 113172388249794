import React from 'react';
import ProductCard from './ProductCard';
import SearchFilter from './SearchFilter';

const ProductGrid = ({ products, btnText }) => {
    return (
        <>
        <SearchFilter />
        <div className='row g-2 g-sm-3 mb-5'>
            {products.map(product => (
                <div className='col-12 col-sm-4 col-lg-3'>
                    <ProductCard productData={product} btnText={btnText} />
                </div>
            ))}
        </div>
        </>
    );
};

export default ProductGrid;
