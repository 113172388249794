import './NotFoundPage.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);  // Navigate to the previous page
    };

    return (
        <>
            <SecondaryHeader />
            <div className='text-center p-5'>
                <div className='notFoundInner'>
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <button className='btn btn-primary ps-5 pe-5' onClick={handleGoBack} style={{ padding: '10px 20px', fontSize: '16px' }}>
                        Go Back
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFoundPage;
