import './SalesMen.css';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Link, Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';
import ProductGrid from '../components/ProductGrid';
import b1 from '../assets/images/f1.png'; 
import b2 from '../assets/images/f2.png'; 
import b3 from '../assets/images/f3.png'; 
import b4 from '../assets/images/f4.png'; 
import b5 from '../assets/images/f5.png'; 
import b6 from '../assets/images/f6.png'; 
import b7 from '../assets/images/f7.png'; 
import b8 from '../assets/images/f8.png'; 
import b9 from '../assets/images/f9.png'; 
import b10 from '../assets/images/f10.png'; 
import b11 from '../assets/images/f11.png'; 
import b12 from '../assets/images/f12.png'; 
import b13 from '../assets/images/f13.png'; 
import b14 from '../assets/images/f14.png'; 
import b15 from '../assets/images/f15.png'; 
import b16 from '../assets/images/f16.png'; 
import b17 from '../assets/images/f17.png'; 
import b18 from '../assets/images/f18.png'; 
import b19 from '../assets/images/f19.png'; 
import b20 from '../assets/images/f20.png'; 
import b21 from '../assets/images/f21.png'; 
import b22 from '../assets/images/f22.png'; 
import b23 from '../assets/images/f23.png'; 
import b24 from '../assets/images/f24.png'; 
import b25 from '../assets/images/f25.png'; 
import b26 from '../assets/images/f26.png'; 
import b27 from '../assets/images/f27.png'; 
import b28 from '../assets/images/f28.png'; 
import b29 from '../assets/images/f29.png'; 
import b30 from '../assets/images/f30.png'; 
import PrimaryHeader from '../components/PrimaryHeader';

const BrandList = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const btnText = "Download PDF"; 

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <PrimaryHeader />
            <div className='container'>
                <div className='text-center pt-5 pb-5'>
                    <h4 className='text-uppercase fw-bold'>All Brands</h4>
                </div>
                <div className='row justify-content-center mb-5 pb-5'>
                    <div className='col-12 col-sm-12'>
                        <div className='row g-3'>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b1} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b2} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b3} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b4} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b5} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b6} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b7} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b8} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b9} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b10} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b11} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b12} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b13} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b14} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b15} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b16} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b17} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b18} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b19} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b20} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b21} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b22} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b23} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b24} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b25} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b26} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b27} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b28} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b29} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                            <div className='col-6 col-sm-3 col-lg-2'>
                                <Link to="/" className='d-block'>
                                    <img src={b30} alt='Brand' className='hoverDiv img-fluid'/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BrandList;
