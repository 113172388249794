import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate } from 'react-router-dom';
import PrimaryHeader from '../components/PrimaryHeader';
import Footer from '../components/Footer';
import Services from '../components/Services';
import Brands from '../components/Brands';
import Testimonials from '../components/Testimonials';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';

const HomePage = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };




    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <PrimaryHeader />
            <div className='container'>
                {/* Services */}
                <Services />
                {/* Brands Models */}
                <Brands />
                {/* Testimonials */}
                <Testimonials />
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
