import styles from './Header3.module.css';
import React, { useState, useEffect } from 'react';
import i18n from '../i18n'; // Import your i18n configuration
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { setCurrentLanguage } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';


const Header3 = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    if (!isAuthenticated) {
        return <Navigate to="/signin" />;
    }

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        // localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
        dispatch(setCurrentLanguage( lang ));
    };

    return (
        <>
        <header className={styles.header}>
            {/* <div className='overly'></div> */}
            <div className='topGradient'></div>
            <div className='headerInner'>
                <HeaderMenu />
                <div className='container'>
                    <div className={styles.filter_col}>
                        <h2 className='text-white font-50 mb-4'>Downloadable Database 
                        For Salesmen</h2>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
};

export default Header3;
