import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, submitTendorForm } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
// import type { UploadProps } from 'antd';
import { Button, message, Upload } from "antd";
import banner from "../assets/images/tendors.png";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";

// const props: UploadProps = {
const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    console.log(info.file.status);
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const TendorsPage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    company_name: "",
    person_name: "",
    person_email: "",
    mobile: "",
    person_designation: "",
    company_address: "",
    comments: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: [],
    vehicleSpecs: [],
    purchaseOrder: [],
    letterOfIntent: [],
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    vehicleSpecs: [],
    purchaseOrder: [],
    letterOfIntent: [],
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
  const validate = () => {
    let formErrors = {};

    if (!formData.company_name)
      formErrors.company_name = "Company name is required";
    if (!formData.person_name)
      formErrors.person_name = "Person name is required";
    if (!formData.person_email) formErrors.person_email = "Email is required";
    if (formData.person_email && !/\S+@\S+\.\S+/.test(formData.person_email)) {
      formErrors.person_email = "Email is invalid";
    }
    if (!formData.mobile) formErrors.mobile = "Mobile number is required";
    if (!formData.person_designation)
      formErrors.person_designation = "Person designation is required";
    // if (!formData.company_address) formErrors.company_address = 'Company address is required';

    // Validate file uploads
    if (fileList.tradeLicense.length === 0)
      formErrors.tradeLicense = "Company Trade License is required";
    if (fileList.vehicleSpecs.length === 0)
      formErrors.vehicleSpecs =
        "Vehicle Technical Specification Sheet is required";
    if (fileList.purchaseOrder.length === 0)
      formErrors.purchaseOrder = "Purchase Order is required";
    if (fileList.letterOfIntent.length === 0)
      formErrors.letterOfIntent = "Letter Of Intent is required";
    // if (!uploadedFiles.tradeLicense) formErrors.tradeLicense = 'Company Trade License is required';
    // if (!uploadedFiles.vehicleSpecs) formErrors.vehicleSpecs = 'Vehicle Technical Specification Sheet is required';

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "comments" && value.length > charLimit) {
      return; // Prevent exceeding character limit
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "comments") {
      setCharCount(value.length); // Update character count for comments
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });
  };
  const beforeUpload = (file) => {
    const isAllowedFileType = allowedFileTypes.includes(file.type);

    if (!isAllowedFileType) {
      message.error(
        `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
      );
    }

    return isAllowedFileType ? false : Upload.LIST_IGNORE; // Prevent upload if file type is not allowed
  };

  const handleFileChange = ({ fileList: newFileList }, fileType) => {
    setFileList({
      ...fileList,
      [fileType]: newFileList,
    });
  };
  const handleUploadChange = (info, fileType) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: info.file,
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: null,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    console.log("formErrors", formErrors, Object.keys(formErrors).length);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const data = new FormData(); // Create FormData object
    data.append("company_name", formData.company_name);
    data.append("person_name", formData.person_name);
    data.append("person_email", formData.person_email);
    data.append("mobile", formData.mobile);
    data.append("person_designation", formData.person_designation);
    data.append("company_address", formData.company_address);
    data.append("comments", formData.comments);

    // Append files to form data
    if (fileList.tradeLicense.length > 0) {
      data.append("company_trade_license", fileList.tradeLicense[0].originFileObj);
    }
    if (fileList.purchaseOrder.length > 0) {
      data.append("purchase_order", fileList.purchaseOrder[0].originFileObj);
    }
    if (fileList.vehicleSpecs.length > 0) {
        data.append("vehicle_technical_spec_sheet", fileList.vehicleSpecs[0].originFileObj);
      }
      if (fileList.letterOfIntent.length > 0) {
        data.append("letter_of_intention", fileList.letterOfIntent[0].originFileObj);
      }  
   const response = await dispatch(submitTendorForm(data));
   if (response?.code == 200) {
    message.success("Submitted successfully!");
  } else {
    message.success(response?.message || "Submission failed!");
  }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      <SecondaryHeader />
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />

              <form onSubmit={handleSubmit}>
                <div className="card rounded-10 bg-white border-0 mb-5">
                  <div className="card-body p-5">
                    <h2 className="mb-4  text-center">Tendors</h2>
                    <div className="row g-3 mb-4 text-start">
                      <div className="col-12 col-sm-6">
                        <InputField
                          placeholder="Company Name *"
                          type="text"
                          name="company_name"
                          value={formData.company_name}
                          onChange={handleChange}
                          customClass={errors.company_name ? "is-invalid" : ""}
                        />
                        {errors.company_name && (
                          <div className="text-danger font-13">
                            {errors.company_name}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        <InputField
                          placeholder="Person Name *"
                          type="text"
                          name="person_name"
                          value={formData.person_name}
                          onChange={handleChange}
                          customClass={errors.person_name ? "is-invalid" : ""}
                        />
                        {errors.person_name && (
                          <div className="text-danger font-13">
                            {errors.person_name}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        <InputField
                          placeholder="Person Email *"
                          type="email"
                          name="person_email"
                          value={formData.person_email}
                          onChange={handleChange}
                          customClass={errors.person_email ? "is-invalid" : ""}
                        />
                        {errors.person_email && (
                          <div className="text-danger font-13">
                            {errors.person_email}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="text-start">
                          <PhoneInput
                            country={"ae"}
                            excludeCountries={["il"]}
                            name="mobile"
                            value={formData.mobile}
                            className="w-100"
                            onChange={handlePhoneChange}
                            inputClass={`form-control ${
                              errors.mobile ? "is-invalid" : ""
                            }`}
                          />
                          {errors.mobile && (
                            <div className="text-danger font-13">
                              {errors.mobile}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <InputField
                          placeholder="Person Designation *"
                          type="text"
                          name="person_designation"
                          value={formData.person_designation}
                          onChange={handleChange}
                          customClass={
                            errors.person_designation ? "is-invalid" : ""
                          }
                        />
                        {errors.person_designation && (
                          <div className="text-danger font-13">
                            {errors.person_designation}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        <InputField
                          placeholder="Company Address"
                          type="text"
                          name="company_address"
                          value={formData.company_address}
                          onChange={handleChange}
                          customClass={
                            errors.company_address ? "is-invalid" : ""
                          }
                        />
                        {errors.company_address && (
                          <div className="text-danger font-13">
                            {errors.company_address}
                          </div>
                        )}
                      </div>
                    </div>
                    <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                      <i className="bi bi-paperclip me-2"></i>{" "}
                      <span>Attachments</span>
                    </h5>
                    <div className="row g-3 mb-4 text-start">
                      <div className="col-12 col-sm-6">
                        {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "tradeLicense")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Company Trade License *
                          </Button>
                        </Upload> */}
                        <Upload
                          fileList={fileList.tradeLicense}
                          beforeUpload={beforeUpload}
                          onChange={(file) =>
                            handleFileChange(file, "tradeLicense")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                          Company Trade License *
                          </Button>
                        </Upload>
                        {errors.tradeLicense && (
                          <div className="text-danger font-13">
                            {errors.tradeLicense}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "vehicleSpecs")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Vehicle Technical Specification Sheet *
                          </Button>
                        </Upload> */}
                        <Upload
                          fileList={fileList.vehicleSpecs}
                          beforeUpload={beforeUpload}
                          onChange={(file) =>
                            handleFileChange(file, "vehicleSpecs")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                          Vehicle Technical Specification Sheet *
                          </Button>
                        </Upload>
                        {errors.vehicleSpecs && (
                          <div className="text-danger font-13">
                            {errors.vehicleSpecs}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-6">
                        {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "purchaseOrder")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Purchase Order
                          </Button>
                        </Upload> */}
                         <Upload
                          fileList={fileList.purchaseOrder}
                          beforeUpload={beforeUpload}
                          onChange={(file) =>
                            handleFileChange(file, "purchaseOrder")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                          Purchase Order
                          </Button>
                        </Upload>
                      </div>
                      <div className="col-12 col-sm-6">
                        {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "letterOfIntent")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Letter of Intention
                          </Button>
                        </Upload> */}
                         <Upload
                          fileList={fileList.letterOfIntent}
                          beforeUpload={beforeUpload}
                          onChange={(file) =>
                            handleFileChange(file, "letterOfIntent")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                          Letter of Intention
                          </Button>
                        </Upload>
                      </div>
                    </div>
                    <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                      <i className="bi bi-chat me-2"></i> <span>Comments</span>
                    </h5>
                    <div className="row g-3 mb-4 text-start">
                      <div className="col-12 col-sm-12">
                        <InputField
                          fieldType="textarea"
                          name="comments"
                          value={formData.comments}
                          placeholder="Write your comments here..."
                          onChange={handleChange}
                          rows={4}
                          customClass="custom-textarea-comments"
                        />
                        <small className="text-muted">
                          {charCount}/{charLimit} characters
                        </small>
                      </div>
                    </div>
                    <div className="row g-3 text-center">
                      <div className="col-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                        >
                          Submit
                        </button>
                        <p className="m-0 text-muted font-15">
                          We usually respond between 30-50 minutes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="scroll-text">
                <p className="text-muted">
                  A Request for Quotation (RFQ) for shipping fees specific to
                  transporting a car involves a comprehensive solicitation of
                  cost estimates from various shipping or logistics companies.
                  The primary goal is to determine the most cost-effective and
                  reliable service for moving the vehicle from one location to
                  another. In such a request, the requester typically provides
                  detailed specifications about the car, including its make,
                  model, weight, and dimensions, as well as the locations for
                  pickup and delivery, which could be either domestic or
                  international. Accurate details are crucial to ensuring that
                  the quotes provided reflect the precise requirements for
                  transporting the vehicle.
                </p>

                <p className="text-muted">
                  The RFQ might also outline the preferred mode of
                  transportation, such as open or enclosed transport, which
                  directly impacts the level of protection the car will receive
                  during transit. Open transport is generally more affordable
                  but exposes the vehicle to the elements, while enclosed
                  transport offers more protection at a higher cost.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TendorsPage;
