import React from 'react';
import { Link } from 'react-router-dom';
import service1 from '../assets/images/s1.png';
import service2 from '../assets/images/s2.png';
import service3 from '../assets/images/s3.png';
import service4 from '../assets/images/s4.png';
import service5 from '../assets/images/s5.png';
import service6 from '../assets/images/s6.png';

const Services = () => {
    return (
        <>
        <div className='servicesList mt-5'>
            <h2 className='mb-4'>Our Services</h2>
            <div className='row g-4'>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service1} className='img-fluid rounded-10' alt='Service 1' />
                            </div>
                            <h5 className='theme-color'>Brands</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/brands' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service2} className='img-fluid rounded-10' alt='Service 2' />
                            </div>
                            <h5 className='theme-color'>Services</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/services' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service3} className='img-fluid rounded-10' alt='Service 3' />
                            </div>
                            <h5 className='theme-color'>Logistics</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/logistics' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service4} className='img-fluid rounded-10' alt='Service 4' />
                            </div>
                            <h5 className='theme-color'>Modifications</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service5} className='img-fluid rounded-10' alt='Service 5' />
                            </div>
                            <h5 className='theme-color'>Payment Methode</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/payment-method' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <div className='card rounded-15 bg-white'>
                        <div className='card-body'>
                            <div className='imgCol mb-3'>
                                <img src={service6} className='img-fluid rounded-10' alt='Service 6' />
                            </div>
                            <h5 className='theme-color'>Make a Deal</h5>
                            <p className='text-muted'>Aliquam hendrerit sollicitudin purus, quis rutrum mi
                            accumsan nec.</p>
                            <Link to='/make-a-deal' className='btn btn-outline-primary btn-lg w-100 font-15 rounded-10'>View <i class="bi bi-arrow-right ms-3"></i> </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Services;
