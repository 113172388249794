import './SignInPage.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction, setCurrentLanguage } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/LanguageSelect';
import i18n from '../i18n'; // Import your i18n configuration
import logo from '../assets/images/blue-logo.png'; // Import the image file
import Swal from 'sweetalert2';
import { Button, message, Space } from 'antd';
import InputField from '../components/InputField';
// import { height } from '@mui/system';

const TrackOrder = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState({
        trackorder: ''
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};

        if (!formData.trackorder) formErrors.trackorder = 'Please enter tracking order number';

        return formErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validate();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // dispatch(submitSupplyContractForm(formData));
        message.success('Submitted successfully!');
    };
    
    
    return (
        <div className="login-bg vh-100">
            <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
                <div className="login-register">
                    <div className='text-center mb-5'>
                        <img src={logo} alt='Logo' width='200' />
                    </div>
                    <div className="card text-center bg-white rounded-20">
                        <div className="card-body p-5">
                            <h2 className='fw-bold theme-color font-26 mb-3'>Track Your Shipment</h2>
                            <p className='theme-color font-12 mb-3'>Enter your Order Number in the field below and submit to receive the latest updates regarding your shipment.</p>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <div className="mb-3 text-start">
                                    <InputField
                                        placeholder="Enter Your Order Number / VIN Number / Invoice Number *"
                                        type="text"
                                        name="trackorder"
                                        value={formData.trackorder}
                                        onChange={handleChange}
                                        customClass={errors.trackorder ? 'font-13 is-invalid' : 'font-13'}
                                    />
                                    {errors.trackorder && <div className="text-danger font-13">{errors.trackorder}</div>}
                                </div>
                                
                                <button 
                                    type="submit" 
                                    style={{ height: '45px' }}
                                    className="btn btn-primary w-100 mt-2 rounded-15 text-uppercase"
                                >
                                    Track you order
                                </button>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackOrder;