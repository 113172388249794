import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction,submitMakeADealForm } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import { Button, message, Upload } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import banner from '../assets/images/make-a-deal.png';
import InputField from '../components/InputField';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

const MakeADeal = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        agent_name: '',
        agent_mobile: '',
        client_name: '',
        client_mobile: '',
        client_location: '',
        make: '',
        model: '',
        year: '',
        color: '',
        quantity: '',
        destination: '',
        agent_targeted_amount: '',
        agent_commission_amount: '',
    });


    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);
    const charLimit = 200;

    const validate = () => {
        let formErrors = {};

        if (!formData.agent_name) formErrors.agent_name = 'Agent name is required';
        if (!formData.agent_mobile) formErrors.agent_mobile = 'Agent mobile is required';
        if (!formData.client_name) formErrors.client_name = 'Client name is required';
        if (!formData.client_mobile) formErrors.client_mobile = 'Client mobile is required';
        if (!formData.client_location) formErrors.client_location = 'Location is required';
        if (!formData.make) formErrors.make = 'Please select make';
        if (!formData.model) formErrors.model = 'Please select model';
        if (!formData.year) formErrors.year = 'Please select year';
        if (!formData.color) formErrors.color = 'Please enter color';
        if (!formData.quantity) formErrors.quantity = 'Please enter quantity';
        if (!formData.destination) formErrors.destination = 'Please enter destination';
        if (!formData.agent_targeted_amount) formErrors.agent_targeted_amount = 'Please enter agent targeted amount';
        if (!formData.agent_commission_amount) formErrors.agent_commission_amount = 'Please enter agent commission amount';

        return formErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'comments' && value.length > charLimit) {
            return; // Prevent exceeding character limit
        }

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'comments') {
            setCharCount(value.length); // Update character count for comments
        }
    };

    const handleAgentPhoneChange = (value) => {
        setFormData({
            ...formData,
            agent_mobile: value
        });
    };
    const handleClientPhoneChange = (value) => {
       setFormData({
            ...formData,
            agent_mobile: value
        });  
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validate();
        // if (Object.keys(formErrors).length > 0) {
        //     console.log('formErrors:',formErrors);
            
        //     setErrors(formErrors);
        //     return;
        // }

        const response = await dispatch(submitMakeADealForm(formData));
        if (response?.code == 200){
            message.success('Submitted successfully!');
        }else{
            message.success( response?.message || 'Submission failed!' );
        }
    };

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='tendorsPage mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <form onSubmit={handleSubmit}>
                                <div className='card rounded-10 bg-white border-0 mb-5'>
                                    <div className='card-body p-5'> 
                                        <h2 className='mb-4 text-center'>Make a Deal</h2>

                                        <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><i className="bi bi-person me-2"></i> <span>Agent Details</span></h5>
                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="agent_name"
                                                    value={formData.agent_name}
                                                    onChange={handleChange}
                                                    placeholder='Agent Name *'
                                                    customClass={errors.agent_name ? 'is-invalid' : ''}
                                                />
                                                {errors.agent_name && <div className="text-danger font-13">{errors.agent_name}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="text-start">
                                                    <PhoneInput
                                                        country={'ae'}
                                                        excludeCountries={['il']}
                                                        name="agent_mobile"
                                                        value={formData.agent_mobile}
                                                        className="w-100"
                                                        onChange={handleAgentPhoneChange}
                                                        inputClass={`form-control ${errors.agent_mobile ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.agent_mobile && <div className="text-danger font-13">{errors.agent_mobile}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><i className="bi bi-person me-2"></i> <span>Client Details</span></h5>
                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                    <InputField
                                                        name="client_name"
                                                        value={formData.client_name}
                                                        onChange={handleChange}
                                                        placeholder='Client Name *'
                                                        customClass={errors.client_name ? 'is-invalid' : ''}
                                                    />
                                                    {errors.client_name && <div className="text-danger font-13">{errors.client_name}</div>}
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="text-start">
                                                        <PhoneInput
                                                            country={'ae'}
                                                            excludeCountries={['il']}
                                                            name="client_mobile"
                                                            value={formData.client_mobile}
                                                            className="w-100"
                                                            onChange={handleClientPhoneChange}
                                                            inputClass={`form-control ${errors.client_mobile ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.client_mobile && <div className="text-danger font-13">{errors.client_mobile}</div>}
                                                    </div>
                                                </div>
                                            <div className='col-12 col-sm-12'>
                                                <InputField
                                                    name="client_location"
                                                    value={formData.client_location}
                                                    onChange={handleChange}
                                                    placeholder='Location *'
                                                    customClass={errors.client_location ? 'is-invalid' : ''}
                                                />
                                                {errors.client_location && <div className="text-danger font-13">{errors.client_location}</div>}
                                            </div>
                                        </div>

                                        <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><CarOutlined className='me-2' /> <span>Car Details</span></h5>
                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    fieldType="select"
                                                    selectOption='Make *'
                                                    name="make"
                                                    value={formData.make}
                                                    onChange={handleChange}
                                                    options={[
                                                        { value: 'toyota', label: 'Toyota' },
                                                        { value: 'honda', label: 'Honda' },
                                                    ]}
                                                    customClass={errors.make ? 'form-select is-invalid' : 'form-select'}
                                                />
                                                {errors.make && <div className="text-danger font-13">{errors.make}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    fieldType="select"
                                                    selectOption='Model *'
                                                    name="model"
                                                    value={formData.model}
                                                    onChange={handleChange}
                                                    options={[
                                                        { value: 'civic', label: 'Civic' },
                                                        { value: 'corolla', label: 'Corolla' },
                                                    ]}
                                                    customClass={errors.model ? 'form-select is-invalid' : 'form-select'}
                                                />
                                                {errors.model && <div className="text-danger font-13">{errors.model}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    fieldType="select"
                                                    selectOption='Year *'
                                                    name="year"
                                                    value={formData.year}
                                                    onChange={handleChange}
                                                    options={[
                                                        { value: '2023', label: '2023' },
                                                        { value: '2024', label: '2024' },
                                                    ]}
                                                    customClass={errors.color ? 'form-select is-invalid' : 'form-select'}
                                                />
                                                {errors.year && <div className="text-danger font-13">{errors.year}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="color"
                                                    value={formData.color}
                                                    onChange={handleChange}
                                                    placeholder='Color *'
                                                    customClass={errors.color ? 'is-invalid' : ''}
                                                />
                                                {errors.color && <div className="text-danger font-13">{errors.color}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="quantity"
                                                    value={formData.quantity}
                                                    onChange={handleChange}
                                                    placeholder='Quantity *'
                                                    customClass={errors.quantity ? 'is-invalid' : ''}
                                                />
                                                {errors.color && <div className="text-danger font-13">{errors.color}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="destination"
                                                    value={formData.destination}
                                                    onChange={handleChange}
                                                    placeholder='Destination *'
                                                    customClass={errors.destination ? 'is-invalid' : ''}
                                                />
                                                {errors.destination && <div className="text-danger font-13">{errors.destination}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="agent_targeted_amount"
                                                    value={formData.agent_targeted_amount}
                                                    onChange={handleChange}
                                                    placeholder='Agent’s Targeted Amount *'
                                                    customClass={errors.agent_targeted_amount ? 'is-invalid' : ''}
                                                />
                                                {errors.agent_targeted_amount && <div className="text-danger font-13">{errors.agent_targeted_amount}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="agent_commission_amount"
                                                    value={formData.agent_commission_amount}
                                                    onChange={handleChange}
                                                    placeholder='Agent’s Commission Amount *'
                                                    customClass={errors.agent_commission_amount ? 'is-invalid' : ''}
                                                />
                                                {errors.agent_commission_amount && <div className="text-danger font-13">{errors.agent_commission_amount}</div>}
                                            </div>
                                        </div>

                                        <div className='row g-3 mb-4 text-center'>
                                            <div className='col-12 col-sm-12'>
                                                <button type='submit' className='btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3'>Submit</button>
                                                <p className='m-0 text-muted font-15'>We usually respond between 30-50 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MakeADeal;
