import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { authReducer } from './auth/authReducer';

// Define token expiration duration in days
const TOKEN_EXPIRATION_DAYS = 3;

// Function to load user from localStorage and handle expiration
const loadUserFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en'; // Default to 'en' if not set
    let authState = { isAuthenticated: false, user: null, error: null, language: selectedLanguage };
    
    if (user) {
        const userData = JSON.parse(user);
        const currentDate = new Date();
        const expirationDate = new Date(userData.expiration);

        // Check if the token is expired
        if (currentDate <= expirationDate) {
            authState = { ...authState, isAuthenticated: true, user: userData };
        } else {
            localStorage.removeItem('user'); // Remove expired token
        }
    }

    return authState;
};

// Middleware to save state to localStorage
const saveToLocalStorage = store => next => action => {
    const result = next(action);
    const state = store.getState();
    
    // Only save auth state to localStorage
    const { auth } = state;
    if (auth.isAuthenticated) {
        const userWithExpiration = {
            ...auth.user,
            expiration: new Date(Date.now() + TOKEN_EXPIRATION_DAYS * 24 * 60 * 60 * 1000) // Set expiration date
        };
        localStorage.setItem('user', JSON.stringify(userWithExpiration));
    } else {
        localStorage.removeItem('user');
    }

    if (auth.language) {
        localStorage.setItem('selectedLanguage', auth.language);
    }
    
    return result;
};

const rootReducer = combineReducers({
    auth: authReducer,
});

export const store = createStore(
    rootReducer,
    {
        auth: loadUserFromLocalStorage() // Initialize state from local storage
    },
    applyMiddleware(thunk, saveToLocalStorage) // Add saveToLocalStorage middleware
);
