import '../components/Testimonials.css';
import React from 'react';
import { Link } from 'react-router-dom';
import b1 from '../assets/images/b1.png';
import b2 from '../assets/images/b2.png';
import b3 from '../assets/images/b3.png';
import b4 from '../assets/images/b4.png';
import b5 from '../assets/images/b5.png';
import b6 from '../assets/images/b6.png';
import comma from '../assets/images/comma.png';
import userIcon from '../assets/images/user-ico.png';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const Testimonials = () => {
    return (
        <div className='carouselCol pt-5 pb-5 mb-5'>
            <div className='d-flex justify-content-between align-items-center'>
                <h2 className='mb-4 text-capitalize'>What our customers say</h2>
                <Link to='/' className='text-dark text-decoration-none'>Show All Testimonials <i class="bi bi-arrow-right ms-2"></i></Link>
            </div>
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false}>
                <div className='brand-carousel'>
                    <div className='row g-3'>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='brand-carousel'>
                    <div className='row g-3'>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Link className='text-decoration-none text-dark' to="/">
                                <div className='border rounded-10 text-start bg-white'>
                                    <div className='d-flex justify-content-between align-items-center p-4'>
                                        <h5>Great Work</h5>
                                        <img src={comma} alt='Icon' />
                                    </div>
                                    <div className='p-4 pt-0'>
                                        <p className='text-muted font-14'>“Amazing design, easy to customize and a design quality superlative account on its cloud platform for the optimized performance. And we didn’t on our original designs.”</p>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center p-4'>
                                        <img src={userIcon} className='rounded-pill me-3' alt='Icon' />
                                        <div>
                                            <h6>Leslie Alexander</h6>
                                            <p className='text-muted font-13 m-0'>Facebook</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export default Testimonials;
