import '../components/Footer.css';
import React from 'react';
import { Link } from 'react-router-dom';
import googleApp from '../assets/images/google-app.png';
import appleApp from '../assets/images/apple-app.png';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get current year dynamically
    return (
        <>
        <footer>
            <div className='container'>
                <div className='row g-4'>
                    <div className='col-6 col-sm-3'>
                        <h5 className='text-white mb-3'>Company</h5>
                        <div className='row'>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">About Us</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Carrers</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Our Team</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/contact-us">Contact Us</Link></div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <h5 className='text-white mb-3'>Our Brands</h5>
                        <div className='row'>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Toyota</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Porsche</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Audi</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">BMW</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Ford</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Nissan</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Peugeot</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Volkswagen</Link></div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <h5 className='text-white mb-3'>Engine Type</h5>
                        <div className='row'>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Petrol / Diesel</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Hybrid</Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/">Electric</Link></div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <h5 className='text-white mb-3'>Our Mobile App</h5>
                        <div className='row mb-3'>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/"><img src={appleApp} alt='Logo' className='img-fluid' /></Link></div>
                            <div className='col-12 pt-2 pb-2'><Link className='text-white text-decoration-none font-14' to="/"><img src={googleApp} alt='Logo' className='img-fluid' /></Link></div>
                        </div>
                        <h5 className='text-white mb-3'>Connect With Social Media</h5>
                        <div>
                            <Link to='/' className='text-white'><i class="bi bi-facebook pe-3"></i></Link>
                            <Link to='/' className='text-white'><i class="bi bi-youtube ps-3 pe-3"></i></Link>
                            <Link to='/' className='text-white'><i class="bi bi-instagram ps-3 pe-3"></i></Link>
                            <Link to='/' className='text-white'><i class="bi bi-linkedin ps-3 pe-3"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerBottom pt-3 pb-3 mt-5'>
                <div className='container d-flex justify-content-between'>
                    <p className='text-white m-0'>© {currentYear} Cruisemotors.co. All rights reserved.</p>
                    <div>
                        <Link className='text-white text-decoration-none font-14 me-3' to="/terms-and-condition">Terms & Conditions</Link>
                        <Link className='text-white text-decoration-none font-14' to="/privacy-policy">Privacy Policy</Link>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
};



export default Footer;
