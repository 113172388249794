import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction, submitRequestQuotationForm } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/logistics.png';
import log1 from '../assets/images/log1.png';
import log2 from '../assets/images/log2.png';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { message, Upload } from 'antd';
import InputField from '../components/InputField';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';
import PhoneInput from 'react-phone-input-2';
import DatePicker from 'react-datepicker';

const OfferVehicle = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [rows, setRows] = useState([{ quantity: 1, make: '', model: '', year: '' }]);
    const [formData, setFormData] = useState({
        location: '',
        company_name: '',
        designation: '',
        interior_color: '',
        exterior_color: '',
        production_date: '',
        engine_size: '',
        fuel_type: '',
        steering:'',
        asking_price: '',
        sale_method: '',
        comment: '',
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};
        let rowErrors = [];

        if (!formData.location) formErrors.location = 'Enter location';
        if (!formData.company_name) formErrors.company_name = 'Enter company name';

        // Validate rows
        rows.forEach((row, index) => {
            let rowError = {};
            if (!row.make) rowError.make = 'Make is required';
            if (!row.model) rowError.model = 'Model is required';
            if (!row.year) rowError.year = 'Year is required';
            if (Object.keys(rowError).length > 0) {
                rowErrors[index] = rowError;
            }
        });

        return { ...formErrors, rowErrors };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index] = { ...updatedRows[index], [name]: value };
        setRows(updatedRows);
    };

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    const addRow = () => {
        setRows([...rows, { quantity: 1, make: '', model: '', year: '' }]);
    };

    const removeRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const increment = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].quantity += 1;
        setRows(updatedRows);
    };

    const decrement = (index) => {
        const updatedRows = [...rows];
        if (updatedRows[index].quantity > 1) {
            updatedRows[index].quantity -= 1;
        }
        setRows(updatedRows);
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            mobile: value
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            delivery_date: date
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validate();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Combine form data with row data
        const combinedData = {
            ...formData,
            items: rows
        };

        dispatch(submitRequestQuotationForm(combinedData));
        message.success('Submitted successfully!');
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <div className='card rounded-10 bg-white border-0 mb-5'>
                                <div className='card-body p-5'>   
                                    <h2 className='mb-4 text-center'>Offer Us Vehicle</h2>
                                    
                                    
                                    <form onSubmit={handleSubmit}>
                                    <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><i className="bi bi-person me-2"></i> <span>Client Details</span></h5>
                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    placeholder="Location *"
                                                    type="location"
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                    customClass={errors.location ? 'is-invalid' : ''}
                                                />
                                                {errors.location && <div className="text-danger font-13">{errors.location}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    placeholder="Company Name *"
                                                    type="text"
                                                    name="company_name"
                                                    value={formData.company_name}
                                                    onChange={handleChange}
                                                    customClass={errors.company_name ? 'is-invalid' : ''}
                                                />
                                                {errors.company_name && <div className="text-danger font-13">{errors.company_name}</div>}
                                            </div>
                                            <div className='col-12 col-sm-12'>
                                                <InputField
                                                    placeholder="Designation"
                                                    type="text"
                                                    name="designation"
                                                    value={formData.designation}
                                                    onChange={handleChange}
                                                    customClass={errors.designation ? 'is-invalid' : ''}
                                                />
                                                {errors.designation && <div className="text-danger font-13">{errors.designation}</div>}
                                            </div>
                                        </div>
                                        <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><i className="bi bi-car-front me-2"></i> <span>Vehicle Details</span></h5>
                                        {rows.map((row, index) => (
                                            <div className='row g-3 mb-3' key={index}>
                                                <div className='col-11'>
                                                    <div className='row g-3'>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputGroup className="mb-3">
                                                                <Button variant="outline-secondary" className='h-100' onClick={() => decrement(index)}>
                                                                    <i className="bi bi-dash"></i>
                                                                </Button>
                                                                <FormControl
                                                                    type="text"
                                                                    readOnly
                                                                    name="quantity"
                                                                    value={row.quantity}
                                                                    onChange={(e) => handleRowChange(index, e)}
                                                                    min="1"
                                                                    className='font-13 text-center'
                                                                />
                                                                <Button variant="outline-secondary" onClick={() => increment(index)}>
                                                                    <i className="bi bi-plus"></i>
                                                                </Button>
                                                            </InputGroup>
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Make *'
                                                                name="make"
                                                                value={row.make}
                                                               
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: 'toyota', label: 'Toyota' },
                                                                    { value: 'honda', label: 'Honda' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.make ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].make && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].make}</div>
                                                            )}
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Model *'
                                                                name="model"
                                                                value={row.model}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: 'civic', label: 'Civic' },
                                                                    { value: 'corolla', label: 'Corolla' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.model ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].model && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].model}</div>
                                                            )}
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Year *'
                                                                name="year"
                                                                value={row.year}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: '2020', label: '2020' },
                                                                    { value: '2021', label: '2021' },
                                                                    { value: '2022', label: '2022' },
                                                                    { value: '2023', label: '2023' },
                                                                    { value: '2024', label: '2024' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.year ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].year && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].year}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-1'>
                                                    <button type='button' className='btn btn-danger w-100' onClick={() => removeRow(index)}>
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}

                                        <div className='text-end mb-3'>
                                            <div className='row g-3'>
                                                <div className='col-11'></div>
                                                <div className='col-1'>
                                                    <button type='button' className='btn btn-primary w-100' onClick={addRow}>
                                                        <i className="bi bi-plus-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="interior_color"
                                                    value={formData.interior_color}
                                                    onChange={handleChange}
                                                    placeholder='Interior Color'
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="exterior_color"
                                                    value={formData.exterior_color}
                                                    onChange={handleChange}
                                                    placeholder='Exterior Color'
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="production_date"
                                                    value={formData.production_date}
                                                    onChange={handleChange}
                                                    placeholder='Production Date'
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="engine_size"
                                                    value={formData.engine_size}
                                                    onChange={handleChange}
                                                    placeholder='Engine Size'
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    fieldType='select'
                                                    name="fuel_type"
                                                    value={formData.fuel_type}
                                                    onChange={handleChange}
                                                    customClass='form-select'
                                                    selectOption='Fuel Type'
                                                    options={[
                                                        { value: '01', label: 'Petrol / Diesel' },
                                                        { value: '02', label: 'Hybrid' },
                                                        { value: '03', label: 'Electric' },
                                                    ]}
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="steering"
                                                    value={formData.steering}
                                                    onChange={handleChange}
                                                    placeholder='Steering'
                                                />
                                            </div>
                                        </div>
                                        <h5 className='text-start mb-3 d-flex justify-content-start align-items-center'><i className="bi bi-cart me-2"></i> <span>Offer Details</span></h5>
                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="asking_price"
                                                    value={formData.asking_price}
                                                    onChange={handleChange}
                                                    placeholder='Asking Price'
                                                />
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="sale_method"
                                                    value={formData.sale_method}
                                                    onChange={handleChange}
                                                    placeholder='Preferred Sale Method '
                                                />
                                            </div>
                                            <div className='col-12 col-sm-12'>
                                                <InputField
                                                    fieldType="textarea"
                                                    name="comment"
                                                    value={formData.comment}
                                                    onChange={handleChange}
                                                    placeholder='Write your comments '
                                                />
                                            </div>
                                        </div>
                                        <div className='row g-3 text-center'>
                                            <div className='col-12 col-sm-12'>
                                                <button type='submit' className='btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3'>Submit</button>
                                                <p className='m-0 text-muted font-15'>We usually respond between 30-50 minutes</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                            <div className="scroll-text">
                                <p className='text-muted'>A Request for Quotation (RFQ) for shipping fees specific to transporting a car involves a comprehensive solicitation of cost estimates from various shipping or logistics companies. The primary goal is to determine the most cost-effective and reliable service for moving the vehicle from one location to another. In such a request, the requester typically provides detailed specifications about the car, including its make, model, weight, and dimensions, as well as the locations for pickup and delivery, which could be either domestic or international. Accurate details are crucial to ensuring that the quotes provided reflect the precise requirements for transporting the vehicle.</p>

                                <p className='text-muted'>The RFQ might also outline the preferred mode of transportation, such as open or enclosed transport, which directly impacts the level of protection the car will receive during transit. Open transport is generally more affordable but exposes the vehicle to the elements, while enclosed transport offers more protection at a higher cost.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OfferVehicle;
