import React from 'react';
import Select from 'react-select';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

const languageOptions = [
    { value: 'en', label: 'EN', flag: 'US' },
    { value: 'es', label: 'ES', flag: 'ES' },
    { value: 'fr', label: 'FR', flag: 'FR' },
    { value: 'de', label: 'DE', flag: 'DE' },
    { value: 'zh', label: 'ZH', flag: 'CN' },
    { value: 'ru', label: 'RU', flag: 'RU' },
    { value: 'ja', label: 'JA', flag: 'JP' },
    { value: 'ar', label: 'AR', flag: 'SA' },
    { value: 'pt', label: 'PT', flag: 'BR' },
    { value: 'it', label: 'IT', flag: 'IT' },
];

const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Flag country={data.flag} style={{ marginRight: 10 }} />
        {data.label}
    </div>
);

const customOption = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
        <div
            ref={innerRef}
            {...innerProps}
            style={{ display: 'flex', alignItems: 'center', padding: '8px' }}
        >
            <Flag country={data.flag} style={{ marginRight: 10 }} />
            {data.label}
        </div>
    );
};

const LanguageSelect = ({ selectedLanguage, onLanguageChange }) => {
    const { i18n } = useTranslation();

    const handleChange = (selectedOption) => {
        const lang = selectedOption.value;
        onLanguageChange(lang);
        i18n.changeLanguage(lang);
    };

    return (
        <Select
            className='languageOptions font-13'
            value={languageOptions.find(option => option.value === selectedLanguage)}
            onChange={handleChange}
            options={languageOptions}
            getOptionLabel={(option) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag country={option.flag} style={{ marginRight: 10 }} />
                    {option.label}
                </div>
            )}
            getOptionValue={(option) => option.value}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            isSearchable={false} // Disable the search input
            styles={{
                control: (provided) => ({
                    ...provided,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                }),
                menu: (provided) => ({
                    ...provided,
                    borderRadius: '4px',
                }),
            }}
        />
    );
};

export default LanguageSelect;