// import './ServicePage.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/cryptocurrency.png';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';


const CryptoCurrency = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <h2 className='mb-4 text-center'>USDT & Cryptocurrency</h2>
                        
                            <p className='text-muted'>Terms and conditions for car transactions are essential in establishing the legal framework that governs the sale, purchase, or leasing of a vehicle. These terms outline the obligations and responsibilities of both the buyer and the seller, ensuring that the transaction is clear, transparent, and legally enforceable. In a car purchase, the terms and conditions typically cover crucial details such as the price of the vehicle, payment methods, warranties, delivery timelines, and the transfer of ownership. These terms protect both parties by setting expectations for the transaction, including what happens if one party fails to meet their obligations.</p>

                            <p className='text-muted'>When it comes to warranties and guarantees, the terms and conditions define what is covered by the manufacturer or dealer, including the duration of the warranty and what types of repairs or defects are included. This may also include stipulations regarding regular maintenance, repairs, and the conditions under which the warranty could be voided, such as improper use of the vehicle or failure to follow maintenance guidelines. Clear terms about warranties provide peace of mind to the buyer, ensuring that they are covered in the event of defects or breakdowns, while also protecting the seller from unwarranted claims.</p>

                            <p className='text-muted'>In the case of leasing or financing a car, the terms and conditions outline the specific requirements of the lease or loan agreement. This includes the payment schedule, interest rates, penalties for late payments, and any additional fees associated with the financing or lease. The agreement may also specify conditions regarding the use of the vehicle, mileage limits, and the return process at the end of the lease period. Having these terms clearly defined helps both the lessor and lessee understand their rights and obligations throughout the term of the lease or loan.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CryptoCurrency;
