import React from 'react';
import { jsPDF } from 'jspdf';
import imgPlaceholder from '../assets/images/img-placeholder.png'; 
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Avatar, Card, Tag } from 'antd';
const { Meta } = Card;

const ProductCard = ({ productData, btnText }) => {
    
    // Function to generate and download PDF
  const downloadPDF = () => {
    const doc = new jsPDF();
    
    // Add product title
    doc.setFontSize(20);
    doc.text(productData.title, 10, 20);

    // Add product image if available
    const imageWidth = 100;  // Width of the image in mm
    const imageHeight = 75;  // Height of the image in mm
    
    const imageUrl = productData.image ? productData.image : imgPlaceholder;
    
    // Add image to PDF
    doc.addImage(imageUrl, 'JPEG', 10, 30, imageWidth, imageHeight); // Adjust dimensions as needed

    // Add product details
    doc.setFontSize(12);
    doc.text(`Kilometers: ${productData.kilometer}`, 10, 110);
    doc.text(`Year: ${productData.year}`, 10, 120);
    doc.text(`Color: ${productData.color}`, 10, 130);

    // Save the PDF
    doc.save(`${productData.title}.pdf`);
  };

    return (
        <>
        <Badge.Ribbon text="New" placement="start" color="#000740">
            <Card
                className='w-100'
                hoverable
                cover={
                <img
                    alt="example"
                    src={productData.image?productData.image:imgPlaceholder}
                />
                }
                // actions={[
                //   <SettingOutlined key="setting" />,
                //   <EditOutlined key="edit" />,
                //   <EllipsisOutlined key="ellipsis" />,
                // ]}
            >
                <Meta
                //   avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
                className='mb-2'
                title={productData.title}
                // description="This is the description"
                />

                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='text-muted font-13 fw-bold'>{productData.kilometer}</div>
                    <div className='text-muted font-13 fw-bold'>{productData.year}</div>
                    <div className='text-muted font-13 fw-bold'>{productData.color}</div>
                </div>
                <button type='button' className='btn btn-primary w-100' onClick={downloadPDF}><i class='bi bi-cloud-download'></i> {btnText}</button>

            </Card>
        </Badge.Ribbon>
        {/* <div className='card rounded-10'>
            <div className='card-body'>
                <div className='inm-col mb-3'>
                    <img src={image} alt={title} title={title} className='img-fluid rounded-10' />
                </div>
                <button type='button' className='btn btn-primary w-100'><i class="bi bi-cloud-download"></i> Download</button>
            </div>
        </div> */}
        </>
    );
};

export default ProductCard;
