import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignInPage from './pages/SignInPage';
import SignUpIndividualPage from './pages/SignUpIndividualPage';
import SignUpAdvanceIndividualPage from './pages/SignUpAdvanceIndividualPage';
import SignUpAsCompanyPage from './pages/SignUpAsCompanyPage';
import SignUpAsAdvanceCompanyPage from './pages/SignUpAsAdvanceCompanyPage';
import HomePage from './pages/HomePage';
import AuthGuard from './components/AuthGuard';
import ServicePage from './pages/ServicePage';
import NotFoundPage from './pages/NotFoundPage';
import TendorsPage from './pages/TendorsPage';
import SupplyContract from './pages/SupplyContract';
import WarrantyInformation from './pages/WarrantyInformation';
import MakeADeal from './pages/MakeADeal';
import Logistics from './pages/Logistics';
import RequestForQuotation from './pages/RequestForQuotation';
import PaymentMethod from './pages/PaymentMethod';
import BankAccountDetail from './pages/BankAccountDetail';
import LetterOfCredit from './pages/LetterOfCredit';
import TermsAndCondition from './pages/TermsAndCondition';
import Cash from './pages/Cash';
import Rules from './pages/Rules';
import CryptoCurrency from './pages/CryptoCurrency';
import ComingSoon from './pages/ComingSoon';
import TrackOrder from './pages/TrackOrder';
import SalesMen from './pages/SalesMen';
import BrandModels from './pages/BrandModels';
import BrandList from './pages/BrandList';
import CrossTrade from './pages/CrossTrade';
import OrderVehicle from './pages/OrderVehicle';
import OfferVehicle from './pages/OfferVehicle';
import Forgotten from './pages/Forgotten';
import ContacyUs from './pages/ContacyUs';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/individual-register" element={<SignUpIndividualPage />} />
            <Route path="/individual-advance-register" element={<SignUpAdvanceIndividualPage />} />
            <Route path="/company-register" element={<SignUpAsCompanyPage />} />
            <Route path="/company-advance-register" element={<SignUpAsAdvanceCompanyPage />} />
            <Route path="/track-order" element={<TrackOrder />} />
            <Route path="/forgotten" element={<Forgotten />} />
            <Route
                path="/coming-soon"
                element={
                    <AuthGuard>
                        <ComingSoon />
                    </AuthGuard>
                }
            />
            <Route
                path="/"
                element={
                    <AuthGuard>
                        <HomePage />
                    </AuthGuard>
                }
            />
            <Route 
                path="/services" 
                element={
                    <AuthGuard>
                        <ServicePage />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/tendors" 
                element={
                    <AuthGuard>
                        <TendorsPage />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/supply-contract" 
                element={
                    <AuthGuard>
                        <SupplyContract />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/warranty-information" 
                element={
                    <AuthGuard>
                        <WarrantyInformation />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/make-a-deal" 
                element={
                    <AuthGuard>
                        <MakeADeal />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/logistics" 
                element={
                    <AuthGuard>
                        <Logistics />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/request-for-quotation" 
                element={
                    <AuthGuard>
                        <RequestForQuotation />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/payment-method" 
                element={
                    <AuthGuard>
                        <PaymentMethod />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/bank-detail" 
                element={
                    <AuthGuard>
                        <BankAccountDetail />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/letter-of-credit" 
                element={
                    <AuthGuard>
                        <LetterOfCredit />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/terms-and-condition" 
                element={
                    <AuthGuard>
                        <TermsAndCondition />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/cash" 
                element={
                    <AuthGuard>
                        <Cash />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/rules" 
                element={
                    <AuthGuard>
                        <Rules />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/crypto-currency" 
                element={
                    <AuthGuard>
                        <CryptoCurrency />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/salesmen" 
                element={
                    <AuthGuard>
                        <SalesMen />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/brand-models" 
                element={
                    <AuthGuard>
                        <BrandModels />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/brands" 
                element={
                    <AuthGuard>
                        <BrandList />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/cross-trade" 
                element={
                    <AuthGuard>
                        <CrossTrade />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/order-vehicle" 
                element={
                    <AuthGuard>
                        <OrderVehicle />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/offer-vehicle" 
                element={
                    <AuthGuard>
                        <OfferVehicle />
                    </AuthGuard>
                } 
            />
            <Route 
                path="/contact-us" 
                element={
                    <AuthGuard>
                        <ContacyUs />
                    </AuthGuard>
                } 
            />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
