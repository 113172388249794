import styles from './SearchFilter.module.css';
import React from 'react';

const SearchFilter = () => {
    return (
        <>
        <div className={`p-2 border ${styles.searchFilter}`}>
            <div className='row'>
                <div className='col-2'>
                    <select className='form-select'>
                        <option selected disabled>Model</option>
                        <option value={1}>Corrola</option>
                        <option value={1}>Camery</option>
                    </select>
                </div>
                <div className='col-2'>
                    <select className='form-select'>
                        <option selected disabled>Interior Color</option>
                        <option value={1}>Black</option>
                        <option value={1}>White</option>
                    </select>
                </div>
                <div className='col-2'>
                    <select className='form-select'>
                        <option selected disabled>Exterior Color</option>
                        <option value={1}>Black</option>
                        <option value={1}>White</option>
                    </select>
                </div>
                <div className='col-2'>
                    <select className='form-select'>
                        <option selected disabled>Steering</option>
                        <option value={1}>Power Steering</option>
                        <option value={1}>Normal Steering</option>
                    </select>
                </div>
                <div className='col-2'>
                    
                </div>
                <div className='col-2'>
                    <button type='button' className='btn btn-primary w-100 h-100 rounded-10'><i class="bi bi-search"></i> Search</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default SearchFilter;
