import '../components/PrimaryHeader.css';
import React, { useState, useEffect } from 'react';
import i18n from '../i18n'; // Import your i18n configuration
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { setCurrentLanguage } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import LanguageSelect from './LanguageSelect';
import logo from '../assets/images/white-logo.png'; // Import the image file
import bc1 from '../assets/images/bc1.png'; 
import bc2 from '../assets/images/bc2.png'; 
import bc3 from '../assets/images/bc3.png'; 



const HeaderMenu = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    if (!isAuthenticated) {
        return <Navigate to="/signin" />;
    }

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        // localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
        dispatch(setCurrentLanguage( lang ));
    };

    return (
        <>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
            <Link className='navbar-brand' to="/"><img src={logo} alt='Logo' width='100' /></Link>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarText" 
                    aria-controls="navbarText" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse ms-4" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white active" aria-current="page" to="/brands">Brands</Link>
                        </li> */}
                        
                        {/* Multi-layer Dropdown for Services */}
                        <li className="nav-item dropdown ps-2 pe-2">
                            <Link 
                                className="nav-link dropdown-toggle text-white" 
                                to="/brands" 
                                role="button" 
                                data-bs-toggle="dropdown" 
                                aria-expanded="false"
                            >
                                Brands
                            </Link>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link className="dropdown-item" to="/"><img src={bc1} className='img-fluid' alt='Brand' width={50} /> Acura</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/"><img src={bc2} className='img-fluid' alt='Brand' width={50} /> Audi</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/"><img src={bc3} className='img-fluid' alt='Brand'  width={50} /> Bentley</Link>
                                </li>
                                {/* <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle" to="#">
                                        Sub Services
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link className="dropdown-item" to="/sub-service-1">Sub Service 1</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/sub-service-2">Sub Service 2</Link>
                                        </li>
                                    </ul>
                                </li> */}
                            </ul>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white" to="/services">Services</Link>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white" to="/logistics">Logistics</Link>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white" to="/modifications">Modifications</Link>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white" to="/payment-method">Payment Method</Link>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                            <Link className="nav-link text-white" to="/make-a-deal">Make a Deal</Link>
                        </li>
                    </ul>
                    <span className="navbar-text d-flex">
                        <button 
                            type="button" 
                            className="btn btn-outline-primary me-2 bg-white text-dark font-13"
                        >
                            <i class="bi bi-geo-alt me-2"></i>
                            Track Your Order
                        </button>
                        <div class="dropdown me-2">
                            <button class="btn btn-outline-primary dropdown-toggle bg-white text-dark font-13 h-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-person me-2"></i>
                                Hi Fahad
                            </button>
                            <ul class="dropdown-menu">
                                <li><Link className='dropdown-item' to="/"> Profile</Link></li>
                                <li><hr class="dropdown-divider" /></li>
                                <li><Link className='dropdown-item' onClick={handleSignOut}><i class="bi bi-box-arrow-left me-2"></i> Logout</Link></li>
                            </ul>
                        </div>
                        
                        <LanguageSelect
                            selectedLanguage={language}
                            onLanguageChange={handleLanguageChange}
                        />
                    </span>
                </div>
            </div>
        </nav>
        </>
    );
};

export default HeaderMenu;
