import axios from 'axios';

const API_URL = 'http://localhost:8000/api/user'; // Replace with your API URL
// const API_URL = 'http://api.cruisemotors.co/api/user';
const getAuthToken = () => localStorage.getItem('authToken');

export const getCsrfToken = () => {
    const tokenElement = document.querySelector('meta[name="csrf-token"]');
    return tokenElement ? tokenElement.getAttribute('content') : '';
};

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': `Bearer ${getAuthToken()}`,
        'X-CSRF-Token': getCsrfToken(), // If using CSRF token
    },
});

export const signIn = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/login`, credentials);
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Sign In Failed';
    }
};

export const signUp = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/register-user`, credentials); 
        return response.data;
    } catch (error) {
        throw error.response.data || 'Sign Up Failed Somthing Wrong!';
    }
};

export const companySignUp = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/register-company`, credentials);
        console.log('companySignUp:',response.data);
        
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Company Sign Up Failed Somthing Wrong!';
    }
};


export const signOut = async () => {
    try {
        await axios.post(`${API_URL}/signout`);
    } catch (error) {
        throw 'Sign Out Failed';
    }
};

export const tendorSubmit = async (credentials) => {
    try {
        const response =  await axiosInstance.post(`${API_URL}/contractsform/tendor`,credentials);
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Submission failed';
    }
};

export const requestQuotationSubmit = async (credentials) => {
    try {
       const response =  await axiosInstance.post(`${API_URL}/contractsform/quotation`,credentials);
       console.log('mlkn',response.data);
       return response.data; 
    } catch (error) {
        throw error?.response?.data || 'Submission failed';
    }
};

export const makeaDealSubmit = async (credentials) => {
   
    try {
       const response = await axiosInstance.post(`${API_URL}/contractsform/deal`,credentials);
        console.log('mlkn',response.data);
       return response.data;
    } catch (error) {
        throw error?.response?.data || 'Submission failed';
    }
};

export const supplyContractSubmit = async (credentials) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/contractsform/supply-contract`,credentials);
        return response.data;
    } catch (error) {
        throw 'Submission failed';
    }
};

export const forgotPassword = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/forgot-password`, credentials);
       
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Sign In Failed';
    }
};

export const verifyOtp = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/verify-otp`, credentials);
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Sign In Failed';
    }
};

export const resetPassword = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/reset-password`, credentials);
       
        return response.data;
    } catch (error) {
        throw error?.response?.data || 'Sign In Failed';
    }
};




