import './SignInPage.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction } from '../redux/auth/authActions';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/LanguageSelect';
import i18n from '../i18n'; // Import your i18n configuration
import logo from '../assets/images/blue-logo.png'; // Import the image file
// import Flag from 'react-flags-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RedirectToHome from '../components/RedirectToHome';

// List of country codes with flags
const countryCodes = [
    { code: '+1', name: 'United States', flag: 'US' },
    { code: '+44', name: 'United Kingdom', flag: 'GB' },
    { code: '+91', name: 'India', flag: 'IN' },
    { code: '+81', name: 'Japan', flag: 'JP' },
    { code: '+971', name: 'United Arab Emirates', flag: 'AE' },
    // Add more countries here
];

const SignUpAdvanceCompanyPage = () => {
    const [checked, setChecked] = React.useState(false);
    const { t } = useTranslation();
    const [personpassport, setPersonPassport] = useState('');
    const [companylicense, setCompanyLicense] = useState('');
    // Error
    const [personPassportError, setPersonPassportError] = useState('');
    const [companyLicenseError, setCompanyLicenseError] = useState('');
    const [checkboxError, setCheckboxError] = useState('');

    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    const dispatch = useDispatch();
    const { isAuthenticated, error } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);  // Navigate to the previous page
    };

    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            setCheckboxError('');
        }
    };
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        let valid = true;

        if (!companylicense) {
            setCompanyLicenseError(t('companylicense_required'));
            valid = false;
        } else {
            setCompanyLicenseError('');
        }

        if (!personpassport) {
            setPersonPassportError(t('personpassport_required'));
            valid = false;
        } else {
            setPersonPassportError('');
        }

        if (!checked) {
            setCheckboxError(t('accept_terms_required'));
            valid = false;
        }

        if (valid) {
            // const fullMobileNumber = `${selectedCountryCode}${mobile}`;
            // dispatch(signInAction({ email, password, mobile: fullMobileNumber }));
        }
    };

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
    };

    const handleCompanyLicenseChange = (e) => {
        setCompanyLicense(e.target.value);
        if (e.target.value) {
            setCompanyLicenseError('');
        }
    };

    const handlePersonPassportChange = (e) => {
        setPersonPassport(e.target.value);
        if (e.target.value) {
            setPersonPassportError('');
        }
    };

    return (
        <>
        <RedirectToHome />
        <div className="login-bg h-100">
            <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
                <div className="login-register">
                    <div className='text-center mb-5'>
                        <img src={logo} alt='Logo' width='200' />
                    </div>
                    <div className="card text-center rounded-20 bg-white">
                        <div className="card-body p-5">
                            <h2 className='mb-3 fw-bold theme-color font-26'>{t('adv_company_title')}</h2>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                
                                <div className="mb-3 text-start">
                                    <label htmlFor="companylicense" className="form-label">{t('companylicense')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="file"
                                        placeholder={t('companylicense_placeholder')}
                                        className={`form-control ${companyLicenseError ? 'is-invalid' : ''}`}
                                        id="companylicense"
                                        value={companylicense}
                                        onChange={handleCompanyLicenseChange}
                                    />
                                    {companyLicenseError && <div className="invalid-feedback">{companyLicenseError}</div>}
                                </div>
                                <div className="mb-3 text-start">
                                    <label htmlFor="personpassport" className="form-label">{t('personpassport')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="file"
                                        placeholder={t('personpassport_placeholder')}
                                        className={`form-control ${personPassportError ? 'is-invalid' : ''}`}
                                        id="personpassport"
                                        value={personpassport}
                                        onChange={handlePersonPassportChange}
                                    />
                                    {personPassportError && <div className="invalid-feedback">{personPassportError}</div>}
                                </div>
                               
                                    
                                <button 
                                    type="submit" 
                                    style={{ height: '45px' }}
                                    className="btn btn-primary w-100 mt-2 rounded-15"
                                >
                                    {t('register_btn')}
                                </button>
                                {error && (
                                    <div className="text-danger mt-2">
                                        {typeof error === 'string' ? error : t('sign_in_failed')}
                                    </div>
                                )}
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            className='form-check-input'
                                            id='termsconditions'
                                            onChange={handleCheckboxChange}
                                        />
                                        <label class="form-check-label font-13" for="termsconditions">
                                            Accept Terms & Conditions
                                        </label>
                                    </div>
                                    <span className='font-13'>{t('already_account')} <Link className='theme-color fw-bold text-capitalize' to="/signin">{t('login_txt')}</Link></span>
                                </div>
                                {checkboxError && <div className="text-danger font-12 mt-1 text-uppercase">{checkboxError}</div>}
                                <div className='d-flex justify-content-between align-items-center mt-3 text-center'>
                                    <div className='text-center mt-2'>
                                        <Link className='theme-color font-14  text-capitalize' onClick={handleGoBack}>Back</Link>
                                    </div>
                                    <div>
                                        <LanguageSelect
                                            selectedLanguage={language}
                                            onLanguageChange={handleLanguageChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

export default SignUpAdvanceCompanyPage;