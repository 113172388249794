// import './ServicePage.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/payment-methode.png';
import img1 from '../assets/images/p1.png';
import img2 from '../assets/images/p2.png';
import img3 from '../assets/images/p3.png';
import img4 from '../assets/images/p4.png';
import img5 from '../assets/images/p5.png';
import img6 from '../assets/images/p6.png';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

const PaymentMethod = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-9'>
                        <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <h2 className='mb-4 text-center'>Payment Methods</h2>
                            <div className='row g-4 mb-5 justify-content-center'>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/bank-detail' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img1} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Bank Account Detals</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/letter-of-credit' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img2} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Letter Of Credit</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/cash' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img4} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Cash</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/rules' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img5} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>60% | 40% Rules</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/crypto-currency' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img6} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>USDT & Cryptocurrency</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/terms-and-condition' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol position-relative'>
                                                    <img src={img3} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Terms and Conditions</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            
                            <p className='text-muted'>Documentation and certificates are integral components in the automotive industry, ensuring the safety, reliability, and legal compliance of vehicles. Documentation for cars includes a range of materials such as user manuals, maintenance records, service logs, and technical guides. </p>

                            <Link className='theme-color' to='/terms-and-condition'>Terms and Conditions </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentMethod;
