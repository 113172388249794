import React from 'react';

const InputField = ({
  label,
  type = 'text',        // Default input type is 'text'
  value,
  placeholder,
  name,
  onChange,
  className = 'form-control font-13 p-2 ps-3 pe-3 form-control',  // Default Bootstrap class for input/select/textarea
  customClass = '',      // Custom class prop
  fieldType = 'input',   // New fieldType prop to differentiate between input, select, textarea
  options = [],          // Options for select field
  rows = 3,              // Default rows for textarea
  selectOption = '-- Select --',              // Default Select Option
}) => {
  return (
    <div>
      {label && <label className="form-label">{label}</label>}

      {fieldType === 'input' && (
        <input
          type={type}
          className={`${className} ${customClass}`}  // Combine Bootstrap and custom class
          value={value}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
        />
      )}

      {fieldType === 'select' && (
        <select
          className={`${className} ${customClass}`}  // Combine Bootstrap and custom class for select
          value={value}
          name={name}
          onChange={onChange}
        >
          <option value="" selected disabled>{selectOption}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {fieldType === 'textarea' && (
        <textarea
          className={`${className} ${customClass}`}  // Combine Bootstrap and custom class for textarea
          value={value}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          rows={rows}  // Dynamic rows prop for textarea
        />
      )}
    </div>
  );
};

export default InputField;
