import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction, submitSupplyContractForm } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { UploadOutlined } from '@ant-design/icons';
// import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import banner from '../assets/images/tendors.png';
import InputField from '../components/InputField';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

// const props: UploadProps = {
const props = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
        console.log(info.file.status);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

const ContacyUs = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });

    const [uploadedFiles, setUploadedFiles] = useState({
        tradeLicense: null,
        vehicleSpecs: null,
        purchaseOrder: null,
        letterOfIntent: null,
    });

    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);
    const charLimit = 200;

    const validate = () => {
        let formErrors = {};

        if (!formData.first_name) formErrors.first_name = 'First name is required';
        if (!formData.last_name) formErrors.last_name = 'Last name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email is invalid';
        }
        if (!formData.mobile) formErrors.mobile = 'Mobile number is required';
        // if (!formData.message) formErrors.message = 'Please enter your message';

        return formErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'comments' && value.length > charLimit) {
            return; // Prevent exceeding character limit
        }

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'comments') {
            setCharCount(value.length); // Update character count for comments
        }
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            mobile: value
        });
    };

    const handleUploadChange = (info, fileType) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setUploadedFiles({
                ...uploadedFiles,
                [fileType]: info.file,
            });
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setUploadedFiles({
                ...uploadedFiles,
                [fileType]: null,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validate();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        dispatch(submitSupplyContractForm(formData));
        message.success('Submitted successfully!');
    };

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='tendorsPage mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-10'>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-12 col-sm-7'>
                                    <div className='card rounded-10 bg-white border-0'>
                                    <div className='card-body p-5'>
                                        <h2 className='mb-4'>Get In Touch</h2>
                                        <p>Etiam pharetra egestas interdum blandit viverra morbi consequat mi non bibendum
                                        egestas quam egestas nulla.</p>
                                            <div className='row g-3 mb-4 text-start'>
                                                <div className='col-12 col-sm-6'>
                                                    <InputField
                                                        placeholder="First Name *"
                                                        type="text"
                                                        name="first_name"
                                                        value={formData.first_name}
                                                        onChange={handleChange}
                                                        customClass={errors.first_name ? 'is-invalid' : ''}
                                                    />
                                                    {errors.first_name && <div className="text-danger font-13">{errors.first_name}</div>}
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <InputField
                                                        placeholder="Last Name *"
                                                        type="text"
                                                        name="last_name"
                                                        value={formData.last_name}
                                                        onChange={handleChange}
                                                        customClass={errors.last_name ? 'is-invalid' : ''}
                                                    />
                                                    {errors.last_name && <div className="text-danger font-13">{errors.last_name}</div>}
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <InputField
                                                        placeholder="Email *"
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        customClass={errors.email ? 'is-invalid' : ''}
                                                    />
                                                    {errors.email && <div className="text-danger font-13">{errors.email}</div>}
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="text-start">
                                                        <PhoneInput
                                                            country={'ae'}
                                                            excludeCountries={['il']}
                                                            name="mobile"
                                                            value={formData.mobile}
                                                            onChange={handlePhoneChange}
                                                            inputClass={`form-control w-100 ${errors.mobile ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.mobile && <div className="text-danger font-13">{errors.mobile}</div>}
                                                        </div>
                                                </div>
                                                <div className='col-12 col-sm-12'>
                                                <InputField
                                                        fieldType="textarea"
                                                        name="comments"
                                                        value={formData.comments}
                                                        placeholder="Write your comments here..."
                                                        onChange={handleChange}
                                                        rows={4}
                                                        customClass="custom-textarea-comments"
                                                    />
                                                    <small className="text-muted">{charCount}/{charLimit} characters</small>
                                                </div>
                                            </div>
                                     
                                            <div className='row g-3'>
                                                <div className='col-12 col-sm-12'>
                                                    <button type='submit' className='btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3'>Submit</button>
                                                    <p className='m-0 text-muted font-15'>We usually respond between 30-50 minutes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className='col-12 col-sm-5'>
                                        <div className='card rounded-10 bg-white h-100 border-0'>
                                            <div className='card-body p-5 '>
                                                <h4 className='mb-4'>Contact details</h4>
                                                <p className='text-muted font-13'>Etiam pharetra egestas interdum blandit viverra morbi consequat
                                                mi non bibendum egestas quam egestas nulla.</p>
                                                <div className='d-flex justify-content-start align-items-top'>
                                                    <div className='pe-3'>
                                                        <i class="bi bi-geo-alt font-20"></i>
                                                    </div>
                                                    <div>
                                                        <h6>Address</h6>
                                                        <p className='text-muted font-13'>123 Queensberry Street, North
                                                        Melbourne VIC3051, Australia.</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-top mb-4'>
                                                    <div className='pe-3'>
                                                        <i class="bi bi-envelope font-20"></i>
                                                    </div>
                                                    <div>
                                                        <h6>Email</h6>
                                                        <Link className='text-dark font-13' to="mailto:cruisemotorsfzco@gmail.com">cruisemotorsfzco@gmail.com</Link>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-top mb-5'>
                                                    <div className='pe-3'>
                                                        <i class="bi bi-telephone font-20"></i>
                                                    </div>
                                                    <div>
                                                        <h6>Phome</h6>
                                                        <Link className='text-dark font-13' to="">+971560000000</Link>
                                                    </div>
                                                </div>

                                                <h4 className='mb-4'>Follow us</h4>
                                                <div>
                                                    <Link to="/"><i class="bi bi-facebook theme-color font-25 pe-3"></i></Link>
                                                    <Link to="/"><i class="bi bi-instagram theme-color font-25 pe-3"></i></Link>
                                                    <Link to="/"><i class="bi bi-linkedin theme-color font-25 pe-3"></i></Link>
                                                    <Link to="/"><i class="bi bi-twitter theme-color font-25 pe-3"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                            <h2 className='mb-4 mt-5'>Our Offices</h2>
                            <div className='row mt-4'>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-10 bg-white border-0'>
                                        <div className='card-body p-5'>
                                            <div className='d-flex justify-content-start align-items-top'>
                                                <div className='pe-3'>
                                                    <i class="bi bi-geo-alt font-20"></i>
                                                </div>
                                                <div>
                                                    <h6>United Arab Emirates</h6>
                                                    <p className='text-muted font-13 m-0'>416 Dewey Blvd, San Francisco,
                                                    CA 94116, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-10 bg-white border-0'>
                                        <div className='card-body p-5'>
                                            <div className='d-flex justify-content-start align-items-top'>
                                                <div className='pe-3'>
                                                    <i class="bi bi-geo-alt font-20"></i>
                                                </div>
                                                <div>
                                                    <h6>United Arab Emirates</h6>
                                                    <p className='text-muted font-13 m-0'>416 Dewey Blvd, San Francisco,
                                                    CA 94116, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className='card rounded-10 bg-white border-0'>
                                        <div className='card-body p-5'>
                                            <div className='d-flex justify-content-start align-items-top'>
                                                <div className='pe-3'>
                                                    <i class="bi bi-geo-alt font-20"></i>
                                                </div>
                                                <div>
                                                    <h6>United Arab Emirates</h6>
                                                    <p className='text-muted font-13 m-0'>416 Dewey Blvd, San Francisco,
                                                    CA 94116, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContacyUs;
