export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const TENDOR_SUBMIT = 'TENDOR_SUBMIT';
export const SUPPLY_CONTRACT_SUBMIT = 'SUPPLY_CONTRACT_SUBMIT';
export const REQUEST_QUOTATION_SUBMIT = 'REQUEST_QUOTATION_SUBMIT';
export const MAKE_A_DEAL_SUBMIT = 'MAKE_A_DEAL_SUBMIT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ADVANCE_SIGN_UP_SUCCESS = 'ADVANCE_SIGN_UP_SUCCESS';
export const COMPANY_SIGN_UP_SUCCESS = 'COMPANY_SIGN_UP_SUCCESS';
export const COMPANY_ADVANCE_SIGN_UP_SUCCESS = 'COMPANY_ADVANCE_SIGN_UP_SUCCESS';
