import '../components/PrimaryHeader.css';
import React, { useState, useEffect } from 'react';
import i18n from '../i18n'; // Import your i18n configuration
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { setCurrentLanguage } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';


const PrimaryHeader = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    if (!isAuthenticated) {
        return <Navigate to="/signin" />;
    }

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        // localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
        dispatch(setCurrentLanguage( lang ));
    };

    return (
        <>
        <header>
            <div className='overly'></div>
            <div className='topGradient'></div>
            <div className='headerInner'>
                <HeaderMenu />
                <div className='container'>
                    <div className='filter-col m-0'>
                        <h1 className='text-white font-50 mb-4'>Drive Your Dream</h1>
                        <div className='row justify-content-start'>
                            <div className='col-12 col-sm-7'>
                                <div className='card rounded-10 search-filter-header'>
                                    <div className='card-body p-2'>
                                        <div className='row'>
                                            <div className='col-9'>
                                                <div className='row'>
                                                    <div className='col-4 border-end'>
                                                        <select className='form-select w-100'>
                                                            <option selected disabled>Make</option>
                                                            <option>Honda</option>
                                                            <option>Toyota</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-4 border-end'>
                                                        <select className='form-select w-100'>
                                                            <option selected disabled>Model</option>
                                                            <option>Honda</option>
                                                            <option>Toyota</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-4'>
                                                        <input type='text' className='form-control border-0 w-100' placeholder='VIN' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <button className='btn btn-primary w-100 rounded-10'><i class="bi bi-search me-1 font-13"></i> Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
};

export default PrimaryHeader;
