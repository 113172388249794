// import './ServicePage.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/cross-trade.png';
import log1 from '../assets/images/trade1.png';
import log2 from '../assets/images/trade2.png';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';

const CrossTrade = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <h2 className='mb-4 text-center'>Cross Trade</h2>
                            <div className='row g-4 mb-5 justify-content-center'>
                                <div className='col-12 col-sm-6'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/order-vehicle' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol mb-3 position-relative hoverDiv'>
                                                    <img src={log1} className='img-fluid rounded-10' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Order Vehicle</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='card rounded-15 border-0'>
                                        <Link to='/offer-vehicle' className='text-decoration-none text-dark'>
                                            <div className='card-body p-0'>
                                                <div className='imgCol mb-3 position-relative hoverDiv'>
                                                    <img src={log2} className='img-fluid rounded-10 ' alt='Service 6' />
                                                    <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Offer Vehicles</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="scroll-text">
                                <p className='text-muted'>A Request for Quotation (RFQ) for shipping fees specific to transporting a car involves a comprehensive solicitation of cost estimates from various shipping or logistics companies. The primary goal is to determine the most cost-effective and reliable service for moving the vehicle from one location to another. In such a request, the requester typically provides detailed specifications about the car, including its make, model, weight, and dimensions, as well as the locations for pickup and delivery, which could be either domestic or international. Accurate details are crucial to ensuring that the quotes provided reflect the precise requirements for transporting the vehicle.</p>

                                <p className='text-muted'>The RFQ might also outline the preferred mode of transportation, such as open or enclosed transport, which directly impacts the level of protection the car will receive during transit. Open transport is generally more affordable but exposes the vehicle to the elements, while enclosed transport offers more protection at a higher cost.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CrossTrade;
