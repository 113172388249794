import './ServicePage.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import PrimaryHeader from '../components/PrimaryHeader';
import Footer from '../components/Footer';
import Services from '../components/Services';
import Brands from '../components/Brands';
import Testimonials from '../components/Testimonials';

import service1 from '../assets/images/ss1.png';
import service2 from '../assets/images/ss2.png';
import service3 from '../assets/images/ss3.png';
import service4 from '../assets/images/ss4.png';
import service5 from '../assets/images/ss5.png';
import service6 from '../assets/images/ss6.png';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

const ServicePage = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <PrimaryHeader />
            <div className='container'>
                <div className='servicesPageList text-center mt-5 pb-5'>
                    <h2 className='mb-4'>Services</h2>
                    <div className='row g-4'>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/tendors' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service1} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Tendors</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/supply-contract' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service2} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Supply Contract</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service3} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Spare Parts</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service4} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Service Kits</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/warranty-information' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service5} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Warranty</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div className='card rounded-15 border-0 hoverDiv'>
                                <Link to='/cross-trade' className='text-decoration-none text-dark'>
                                    <div className='card-body p-0'>
                                        <div className='imgCol position-relative'>
                                            <img src={service6} className='img-fluid rounded-10' alt='Service 6' />
                                            <span className='short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3'>Cross Trade</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ServicePage;
