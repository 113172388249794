import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction, submitRequestQuotationForm } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/logistics.png';
import log1 from '../assets/images/log1.png';
import log2 from '../assets/images/log2.png';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { message, Upload } from 'antd';
import InputField from '../components/InputField';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

const RequestForQuotation = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [rows, setRows] = useState([{ quantity: 1, make: '', model: '', year: '' }]);
    const [formData, setFormData] = useState({
        location: '',
        destination: '',
        transportType: 'air' // Default transport type
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};
        let rowErrors = [];

        if (!formData.location) formErrors.location = 'Enter location';
        if (!formData.destination) formErrors.destination = 'Enter destination';

        // Validate rows
        rows.forEach((row, index) => {
            let rowError = {};
            if (!row.make) rowError.make = 'Make is required';
            if (!row.model) rowError.model = 'Model is required';
            if (!row.year) rowError.year = 'Year is required';
            if (Object.keys(rowError).length > 0) {
                rowErrors[index] = rowError;
            }
        });

        return { ...formErrors, rowErrors };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index] = { ...updatedRows[index], [name]: value };
        setRows(updatedRows);
    };

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    const addRow = () => {
        setRows([...rows, { quantity: 1, make: '', model: '', year: '' }]);
    };

    const removeRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const increment = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].quantity += 1;
        setRows(updatedRows);
    };

    const decrement = (index) => {
        const updatedRows = [...rows];
        if (updatedRows[index].quantity > 1) {
            updatedRows[index].quantity -= 1;
        }
        setRows(updatedRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validate();
        console.log(formErrors?.rowErrors?.length, Object.keys(formErrors).length > 1);
        
        if (Object.keys(formErrors).length > 1) {
            setErrors(formErrors);
            return;
        }

        if(formErrors?.rowErrors?.length > 0) {
            setErrors(formErrors);
            return;
        }

        // Combine form data with row data
        const combinedData = {
            ...formData,
            items: rows
        };

       const response = await dispatch(submitRequestQuotationForm(combinedData));
       console.log('lhlkhnlknh',response);
       
       if (response?.code == 200){
        message.success('Submitted successfully!');
        }else{
        message.success( response?.message || 'Submission failed!' );
        }
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <div className='card rounded-10 bg-white border-0 mb-5'>
                                <div className='card-body p-5'>   
                                    <h2 className='mb-4 text-center'>Request for Quotation</h2>
                                    <form onSubmit={handleSubmit}>
                                        {rows.map((row, index) => (
                                            <div className='row g-3 mb-3' key={index}>
                                                <div className='col-11'>
                                                    <div className='row g-3'>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputGroup className="mb-3">
                                                                <Button variant="outline-secondary" className='h-100' onClick={() => decrement(index)}>
                                                                    <i className="bi bi-dash"></i>
                                                                </Button>
                                                                <FormControl
                                                                    type="text"
                                                                    readOnly
                                                                    name="quantity"
                                                                    value={row.quantity}
                                                                    onChange={(e) => handleRowChange(index, e)}
                                                                    min="1"
                                                                    className='font-13 text-center'
                                                                />
                                                                <Button variant="outline-secondary" onClick={() => increment(index)}>
                                                                    <i className="bi bi-plus"></i>
                                                                </Button>
                                                            </InputGroup>
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Make *'
                                                                name="make"
                                                                value={row.make}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: 'toyota', label: 'Toyota' },
                                                                    { value: 'honda', label: 'Honda' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.make ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].make && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].make}</div>
                                                            )}
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Model *'
                                                                name="model"
                                                                value={row.model}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: 'civic', label: 'Civic' },
                                                                    { value: 'corolla', label: 'Corolla' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.model ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].model && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].model}</div>
                                                            )}
                                                        </div>
                                                        <div className='col-6 col-sm-3'>
                                                            <InputField
                                                                fieldType="select"
                                                                selectOption='Year *'
                                                                name="year"
                                                                value={row.year}
                                                                onChange={(e) => handleRowChange(index, e)}
                                                                options={[
                                                                    { value: '2020', label: '2020' },
                                                                    { value: '2021', label: '2021' },
                                                                    { value: '2022', label: '2022' },
                                                                    { value: '2023', label: '2023' },
                                                                    { value: '2024', label: '2024' },
                                                                ]}
                                                                customClass={errors?.rowErrors && errors.rowErrors[index]?.year ? 'form-select is-invalid' : 'form-select'}
                                                            />
                                                            {errors.rowErrors && errors.rowErrors[index] && errors.rowErrors[index].year && (
                                                                <div className="text-danger font-13">{errors.rowErrors[index].year}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-1'>
                                                    <button type='button' className='btn btn-danger w-100' onClick={() => removeRow(index)}>
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}

                                        <div className='text-end mb-3'>
                                            <div className='row g-3'>
                                                <div className='col-11'></div>
                                                <div className='col-1'>
                                                    <button type='button' className='btn btn-primary w-100' onClick={addRow}>
                                                        <i className="bi bi-plus-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row g-3 mb-4 text-start'>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                    placeholder='Enter location *'
                                                    customClass={errors.location ? 'is-invalid' : ''}
                                                />
                                                {errors.location && <div className="text-danger font-13">{errors.location}</div>}
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <InputField
                                                    name="destination"
                                                    value={formData.destination}
                                                    onChange={handleChange}
                                                    placeholder='Country / City / Port *'
                                                    customClass={errors.destination ? 'is-invalid' : ''}
                                                />
                                                {errors.destination && <div className="text-danger font-13">{errors.destination}</div>}
                                            </div>
                                            <div className='col-12 col-sm-12'>
                                                <label className='font-13 text-uppercase text-muted mb-2'>Transport Type</label>

                                                <div className='d-flex justify-content-start'>
                                                    <div className="form-check me-5">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="transportType"
                                                            value="air"
                                                            checked={formData.transportType === 'air'}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="air">
                                                            Air
                                                        </label>
                                                    </div>
                                                    <div className="form-check me-5">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="transportType"
                                                            value="sea"
                                                            checked={formData.transportType === 'sea'}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="sea">
                                                            Sea
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="transportType"
                                                            value="land"
                                                            checked={formData.transportType === 'land'}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="land">
                                                            Land
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row g-3 text-center'>
                                            <div className='col-12 col-sm-12'>
                                                <button type='submit' className='btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3'>Submit</button>
                                                <p className='m-0 text-muted font-15'>We usually respond between 30-50 minutes</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                            <div className="scroll-text">
                                <p className='text-muted'>A Request for Quotation (RFQ) for shipping fees specific to transporting a car involves a comprehensive solicitation of cost estimates from various shipping or logistics companies. The primary goal is to determine the most cost-effective and reliable service for moving the vehicle from one location to another. In such a request, the requester typically provides detailed specifications about the car, including its make, model, weight, and dimensions, as well as the locations for pickup and delivery, which could be either domestic or international. Accurate details are crucial to ensuring that the quotes provided reflect the precise requirements for transporting the vehicle.</p>

                                <p className='text-muted'>The RFQ might also outline the preferred mode of transportation, such as open or enclosed transport, which directly impacts the level of protection the car will receive during transit. Open transport is generally more affordable but exposes the vehicle to the elements, while enclosed transport offers more protection at a higher cost.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RequestForQuotation;
