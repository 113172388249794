

import { signIn, signUp, signOut, tendorSubmit, supplyContractSubmit, requestQuotationSubmit, makeaDealSubmit, forgotPassword, verifyOtp, resetPassword,companySignUp } from '../../api/authApi';
import {
    SIGN_IN_SUCCESS,
    SIGN_IN_FAILURE,
    SIGN_OUT,
    SIGN_UP_SUCCESS,
    ADVANCE_SIGN_UP_SUCCESS,
    COMPANY_SIGN_UP_SUCCESS,
    COMPANY_ADVANCE_SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SET_LANGUAGE,
    TENDOR_SUBMIT,
    SUPPLY_CONTRACT_SUBMIT,
    REQUEST_QUOTATION_SUBMIT,
    MAKE_A_DEAL_SUBMIT,
    FORGOT_PASSWORD,
    VERIFY_OTP,
    RESET_PASSWORD
} from './authTypes';


const TOKEN_EXPIRATION_DAYS = 3; // Set expiration duration

export const setCurrentLanguage = (lang)  => async (dispatch) => {
    dispatch({ type: SET_LANGUAGE, payload: lang });
};

export const signInAction = (credentials) => async (dispatch) => {
    // Dummy Signin
    const { email, password } = credentials;
    credentials.device_name = 'web_app';

    const dummyUser = { email: 'test@gmail.com', password: '123456' };
    

    // if (email === dummyUser.email && password === dummyUser.password) {

    //     // Set expiration time
    //     const expirationDate = new Date();
    //     expirationDate.setDate(expirationDate.getDate() + TOKEN_EXPIRATION_DAYS);

    //     const userWithExpiry = {
    //         ...dummyUser,
    //         expiration: expirationDate.toISOString() // Store expiration timestamp
    //     };


    //     // localStorage.setItem('user', JSON.stringify(userWithExpiry)); // Save to local storage
    //     dispatch({ type: SIGN_IN_SUCCESS, payload: userWithExpiry });
    // } else {
    //     dispatch({ type: SIGN_IN_FAILURE, payload: 'Invalid email or password' });
    // }
    // Dummy Signin End


    // Use After you get API

    try {
        const data = await signIn(credentials);
        localStorage.setItem('authToken', data?.data?.token);
        dispatch({ type: SIGN_IN_SUCCESS, payload: data });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Wrong password or username';
        dispatch({ type: SIGN_IN_FAILURE, payload: errorMessage });
    }
};

export const signUpAction = (credentials) => async (dispatch) => {
    dispatch({ type: SIGN_UP_SUCCESS, payload: credentials });

    // Use After you get API

    try {
        const data = await signUp(credentials);
        dispatch({ type: SIGN_UP_SUCCESS, payload: data });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Sign Up Failed Somthing Wrong!';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const companySignUpAction = (credentials) => async (dispatch) => {

    dispatch({ type: COMPANY_SIGN_UP_SUCCESS, payload: credentials });

    // Use After you get API

    try {
        const data = await companySignUp(credentials);
        dispatch({ type: COMPANY_SIGN_UP_SUCCESS, payload: data });
        console.log('dddddd:',data);
        
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Sign Up Failed Somthing Wrong!';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const signOutAction = () => async (dispatch) => {
    // localStorage.removeItem('user'); // Remove from local storage
    dispatch({ type: SIGN_OUT });
    
    // try {
    //     await signOut();
    //     dispatch({ type: SIGN_OUT });
    // } catch (error) {
    //     // Handle sign out error if needed
    // }
};


export const submitTendorForm = (credentials) => async (dispatch) => {
    dispatch({ type: TENDOR_SUBMIT, payload: credentials }); 
console.log('aaaaaaa');

    // Use After you get API

    try {
        const data = await tendorSubmit(credentials);
        dispatch({ type: TENDOR_SUBMIT, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Submission failed';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const submitSupplyContractForm = (credentials) => async (dispatch) => {
    dispatch({ type: SUPPLY_CONTRACT_SUBMIT, payload: credentials }); 

    // Use After you get API

    try {
        const data = await supplyContractSubmit(credentials);
        dispatch({ type: SUPPLY_CONTRACT_SUBMIT, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Submission failed';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const submitRequestQuotationForm = (credentials) => async (dispatch) => {
    dispatch({ type: REQUEST_QUOTATION_SUBMIT, payload: credentials }); 

    // Use After you get API

    try {
        const data = await requestQuotationSubmit(credentials);
        dispatch({ type: REQUEST_QUOTATION_SUBMIT, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Submission failed';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};


export const submitMakeADealForm = (credentials) => async (dispatch) => {
    dispatch({ type: MAKE_A_DEAL_SUBMIT, payload: credentials }); 

    // Use After you get API

    try {
        const data = await makeaDealSubmit(credentials);
        dispatch({ type: MAKE_A_DEAL_SUBMIT, payload: credentials });
        console.log('ijij:',data);
        return data;
    } catch (error) {
        const errorMessage = error.response?.data || 'Submission failed';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const submitForgotPassword = (credentials) => async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD, payload: credentials }); 

    // Use After you get API

    try {
        const data = await forgotPassword(credentials);
        dispatch({ type: FORGOT_PASSWORD, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Failed to send OTP!';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const submitVerifyOtp = (credentials) => async (dispatch) => {
    dispatch({ type: VERIFY_OTP, payload: credentials }); 

    // Use After you get API

    try {
        const data = await verifyOtp(credentials);
        dispatch({ type: VERIFY_OTP, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'OTP verification failed!';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};

export const submitResetPassword = (credentials) => async (dispatch) => {
    dispatch({ type: VERIFY_OTP, payload: credentials }); 

    // Use After you get API

    try {
        const data = await resetPassword(credentials);
        dispatch({ type: RESET_PASSWORD, payload: credentials });
        return data;
    } catch (error) {
        const errorMessage = error?.message || 'Failed to reset password!';
        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};



