import './SignInPage.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUpAction } from '../redux/auth/authActions';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/LanguageSelect';
import i18n from '../i18n'; // Import your i18n configuration
import logo from '../assets/images/blue-logo.png'; // Import the image file
// import Flag from 'react-flags-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RedirectToHome from '../components/RedirectToHome';
import { Modal, Button } from 'react-bootstrap';

// List of country codes with flags
const countryCodes = [
    { code: '+1', name: 'United States', flag: 'US' },
    { code: '+44', name: 'United Kingdom', flag: 'GB' },
    { code: '+91', name: 'India', flag: 'IN' },
    { code: '+81', name: 'Japan', flag: 'JP' },
    { code: '+971', name: 'United Arab Emirates', flag: 'AE' },
    // Add more countries here
];

const SignUpIndividualPage = () => {
    const [checked, setChecked] = React.useState(false);
    const [checkboxError, setCheckboxError] = useState('');
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0].code);
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    // Error
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    const dispatch = useDispatch();
    const { isAuthenticated, error } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);  // Navigate to the previous page
    };

    // Function to handle opening and closing the modal
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            setCheckboxError('');
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
     
        let valid = true;

        if (!name) {
            setNameError(t('name_required'));
            valid = false;
            console.log(valid);
        } else {
            setNameError('');
        }

        if (!email) {
            setEmailError(t('email_required'));
            valid = false;
        } else if(email && !/\S+@\S+\.\S+/.test(email)){
            setEmailError('Email is invalid');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!checked) {
            setCheckboxError(t('accept_terms_required'));
            valid = false;
        }

        if (!mobile) {

            setMobileError(t('mobile_required'));
            valid = false;
            console.log(valid);
        } else {
            setMobileError('');
        }
        
        if (!password) {
            setPasswordError(t('password_required'));
            valid = false;
        } else {
            setPasswordError('');
        }

        

        if (valid) {
            // const fullMobileNumber = `${selectedCountryCode}${mobile}`;
            const fullMobileNumber = '+' + mobile;
            const response = await dispatch(signUpAction({ name, email, password, mobile: fullMobileNumber }));
            if (response?.code === 201) {
                navigate('/signin');
            }
            
        }
    };

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (e.target.value) {
            setNameError('');
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value) {
            setEmailError('');
        }
    };

    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
    };

    const handleMobileChange = (value) => {
        setMobile(value);
        // if (value && value.length >= 10) {
        //     setMobileError('');
        // } else {
        //     setMobileError(t('mobile_invalid'));
        // }
        if (value) {
            setMobileError('');
        } 
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setPasswordError('');
        }
    };

    return (
        <>
        <RedirectToHome />
        <div className="login-bg h-100">
            <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
                <div className="login-register">
                    <div className='text-center mb-5'>
                        <img src={logo} alt='Logo' width='200' />
                    </div>
                    <div className="card text-center bg-white rounded-20">
                        <div className="card-body p-5">
                            <h2 className='mb-3 fw-bold theme-color font-26'>{t('title')}</h2>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                                <div className="mb-3 text-start">
                                    <label htmlFor="name" className="form-label">{t('name')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="name"
                                        placeholder={t('name_placeholder')}
                                        className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                        id="name"
                                        value={name}
                                        onChange={handleNameChange}
                                    />
                                    {nameError && <div className="invalid-feedback">{nameError}</div>}
                                </div>
                                <div className="mb-3 text-start">
                                    <label htmlFor="email" className="form-label">{t('signup_email')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        placeholder={t('email_placeholder')}
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        id="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                                </div>
                              
                                <div className="mb-3 text-start">
                                    <label htmlFor="mobile" className="form-label">{t('mobile')} <span className='text-danger'>*</span></label>
                                    <PhoneInput
                                        country={'ae'}
                                        value={mobile}
                                        excludeCountries={['il']}
                                        onChange={handleMobileChange}
                                        inputClass={`form-control ${mobileError ? 'is-invalid' : ''}`}
                                        
                                        inputStyle={{
                                            width: '100%',
                                            height: '53px', 
                                            borderRadius: '15px'
                                        }}
                                    />
                                    {mobileError && <div className="text-danger font-14 mt-1">{mobileError}</div>}
                                </div>
                                <div className="mb-3 text-start">
                                    <label htmlFor="password" className="form-label">{t('password')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="password"
                                        placeholder={t('password_placeholder')}
                                        className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                        id="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                </div>
                                <button 
                                    type="submit" 
                                    className="btn btn-primary w-100 rounded-15 text-uppercase"
                                    style={{ height: '45px' }}
                                >
                                    {t('register_btn')}
                                </button>
                                {error && (
                                    <div className="text-danger mt-2">
                                        {typeof error === 'string' ? error : t('sign_in_failed')}
                                    </div>
                                )}
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            className='form-check-input'
                                            id='termsconditions'
                                            onChange={handleCheckboxChange}
                                        />
                                        <label class="form-check-label font-13" for="termsconditions">
                                            Accept <span onClick={handleModalShow} className="text-primary" style={{ cursor: 'pointer' }}>Terms & Conditions</span>
                                        </label>
                                    </div>
                                    <span className='font-13'>{t('already_account')} <Link className='theme-color fw-bold text-capitalize' to="/signin">{t('login_txt')}</Link></span>
                                </div>
                                {checkboxError && <div className="text-danger font-12 mt-1 text-uppercase">{checkboxError}</div>}
                                <div className='d-flex justify-content-between align-items-center mt-3 text-center'>
                                    <div className='text-center'>
                                        <Link className='theme-color font-14  text-capitalize' onClick={handleGoBack}>Back</Link>
                                    </div>
                                    <div>
                                        <LanguageSelect
                                            selectedLanguage={language}
                                            onLanguageChange={handleLanguageChange}
                                        />
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Modal for Terms and Conditions */}
        <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Your terms and conditions content goes here...</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default SignUpIndividualPage;