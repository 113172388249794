import './SalesMen.css';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';
import ProductGrid from '../components/ProductGrid';
import productImg from '../assets/images/p-img.png'; 
import modelLogo from '../assets/images/toyota.png'; 
import SecondaryHeader from '../components/SecondaryHeader';

const BrandModels = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const btnText = "Download PDF"; 

    const handleSignOut = () => {
        dispatch(signOutAction());
    };
      
    const products = [
        {
            id: 1,
            title: '2024 Toyota Land Cruiser',
            image: productImg,
            kilometer: '0 KM',
            year: '2024',
            color: 'White'
        },
        {
            id: 2,
            title: '2023 Nissan Patrol',
            image: productImg,
            kilometer: '10,000 KM',
            year: '2023',
            color: 'Black'
        },
        {
            id: 3,
            title: '2024 Toyota Land Cruiser',
            image: productImg,
            kilometer: '0 KM',
            year: '2024',
            color: 'White'
        },
        {
            id: 4,
            title: '2021 Modified Jeep Wrangler',
            image: productImg,
            kilometer: '5,000 KM',
            year: '2021',
            color: 'Green'
        },
        {
            id: 5,
            title: '2020 Modified Ford Mustang',
            image: productImg,
            kilometer: '12,000 KM',
            year: '2020',
            color: 'Red'
        },
        {
            id: 6,
            title: '2025 Tesla Model X',
            image: productImg,
            kilometer: 'Brand New',
            year: '2025',
            color: 'Blue'
        },
        {
            id: 7,
            title: '2025 Mercedes-Benz S-Class',
            image: productImg,
            kilometer: 'Brand New',
            year: '2025',
            color: 'Silver'
        }
    ];


    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='text-center pt-3 pb-3'>
                    <img src={modelLogo} alt='Logo' />
                    <h4 className='text-uppercase fw-bold'>Toyota Models</h4>
                </div>
                <ProductGrid products={products} btnText={btnText} />
            </div>
            <Footer />
        </div>
    );
};

export default BrandModels;
