// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// EN
import enSignIn from './locales/en/signIn.json';
import enSignUpIndividual from './locales/en/signUpIndividual.json';
import enSignUpAdvanceIndividual from './locales/en/signUpAdvanceIndividual.json';
import enSignUpAsCompany from './locales/en/signUpAsCompany.json';
import enSignUpAsAdvanceCompany from './locales/en/signUpAsAdvanceCompany.json';
// ES
import esSignIn from './locales/es/signIn.json';
import esSignUpIndividual from './locales/es/signUpIndividual.json';
import esSignUpAdvanceIndividual from './locales/es/signUpAdvanceIndividual.json';
import esSignUpAsCompany from './locales/es/signUpAsCompany.json';
import esSignUpAsAdvanceCompany from './locales/es/signUpAsAdvanceCompany.json';

// Combine translations for each language
const resources = {
    en: {
        translation: {
            ...enSignIn,
            ...enSignUpIndividual,
            ...enSignUpAdvanceIndividual,
            ...enSignUpAsCompany,
            ...enSignUpAsAdvanceCompany,
        },
    },
    es: {
        translation: {
            ...esSignIn,
            ...esSignUpIndividual,
            ...esSignUpAdvanceIndividual,
            ...esSignUpAsCompany,
            ...esSignUpAsAdvanceCompany,
        },
    },
    // Add other languages here
};

i18n
    .use(LanguageDetector) // Add language detector
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('selectedLanguage') || 'en', // Default language from localStorage or fallback
        fallbackLng: 'en', // Default fallback language
        interpolation: {
            escapeValue: false, // React already does escaping
        },
        detection: {
            order: ['localStorage', 'navigator'], // Order of language detection
            caches: ['localStorage'], // Cache selected language in localStorage
        },
    });

// Detect and set the language initially
const initialLanguage = localStorage.getItem('selectedLanguage') || i18n.language || 'en';
i18n.changeLanguage(initialLanguage);

export default i18n;
