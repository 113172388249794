// import './ServicePage.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/bank-detail.png';
import img1 from '../assets/images/p1.png';
import img2 from '../assets/images/p2.png';
import img3 from '../assets/images/p3.png';
import img4 from '../assets/images/p4.png';
import img5 from '../assets/images/p5.png';
import img6 from '../assets/images/p6.png';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';

const BankAccountDetail = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            <SecondaryHeader />
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                        <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <h2 className='mb-4 text-center'>Bank Account Details</h2>
                            <div className='card rounded-10 mb-5'>
                                <div className='card-body'>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td>Bank Website </td>
                                                <td>
                                                    <div className='d-flex justify-content-between'>
                                                        <span>https://www.emiratesnbd.com/en</span>
                                                        <Link to='#' className='font-20'><i class="bi bi-cloud-download"></i></Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Bank Address </td>
                                                <td>Emirates NBD Building, Al Barsha 2, Dubai</td>
                                            </tr>
                                            <tr>
                                                <td>Account Name</td>
                                                <td>Cruise Motors FZE</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>Dubai, United Arab Emirates</td>
                                            </tr>
                                            <tr>
                                                <td>SWIFT Code</td>
                                                <td>NBD741021</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Link className='theme-color' to='/terms-and-condition'>Terms and Conditions </Link>
                                </div>
                            </div>
                            
                            <p className='text-muted'>Bank account details related to the purchase, sale, or shipment of a car are a crucial aspect of financial transactions, ensuring that payments are securely processed. When dealing with a car transaction, whether purchasing a vehicle from a dealership, making a payment for shipping services, or settling fees related to car maintenance, having accurate and complete bank account information is essential. This typically includes the account holder's name, the bank name, the account number, the bank's routing number, and sometimes the SWIFT code for international transactions. These details ensure that funds are transferred safely and promptly between parties.</p>

                            <p className='text-muted'>In the context of buying or selling a car, bank account details play a central role in the transaction. Buyers need the seller's or dealership’s account information to transfer payment, while sellers need to provide their bank details to receive the funds securely. This is especially important in cases where large sums of money are involved, such as with high-value vehicles. Both parties must ensure that the details provided are accurate to avoid any delays or misdirected payments, which could disrupt the transaction process.</p>

                            <p className='text-muted'>For shipping a car, bank account details are often required when securing payment for the logistics services. Companies handling the transportation of vehicles will provide their account information to facilitate the payment of shipping fees, which might include deposits, full payments, or additional charges for insurance and expedited services. These payments ensure that the car is shipped without delay and in accordance with the agreed-upon terms.</p>

                            <p className='text-muted'>It is also essential to handle bank account information with confidentiality and care, ensuring that sensitive financial details are not shared insecurely. </p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BankAccountDetail;
